import ModalBase from 'modals/BaseModal'
import { Datepicker, Input, SelectBox, Text } from 'components'
import { useForm } from 'react-hook-form'
import { visitorInput } from 'constant/inputInstance'
import { useMutation, useQueryClient } from 'react-query'
import useReception from 'hooks/useReception'
import toast from 'react-hot-toast'
const AddVisitor = ({ children }) => {
  const queryClient = useQueryClient()
  const { addVisitor } = useReception()
  const form = useForm()

  const visitorHandler = useMutation({
    mutationKey: ['addVisitor'],
    mutationFn: addVisitor,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
    },
  })

  const onSubmit = (data) => {
    toast.promise(visitorHandler.mutateAsync(data), {
      loading: 'loading...',
      success: 'Visitor added',
      error: 'something went wrong. please try again.',
    })
  }
  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[1200px]"
      acceptFunctions={form.handleSubmit(onSubmit)}
    >
      <div>
        {/* visitor information */}
        <div className="grid gap-8 sm:grid-cols-1 gird-col-input">
          {visitorInput.map((input) => {
            switch (input.type) {
              case 'select':
                return (
                  <div
                    className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                    key={input.name}
                  >
                    <Text
                      className="text-[15px] text-gray-900 w-auto"
                      size="txtPoppinsMedium15"
                    >
                      {input.label}

                      <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                        *
                      </span>
                    </Text>
                    <SelectBox
                      className="bg-white-A700 border border-indigo-400 border-solid  px-2.5 py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                      placeholderClassName="text-black-900_60"
                      indicator={
                        <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                          <img
                            className="h-5 w-5 rounded-[50%]"
                            src="/images/img_uangledown.svg"
                            alt="u:angle-down"
                          />
                        </div>
                      }
                      name={input.name}
                      options={input.option}
                      isSearchable={true}
                      placeholder={input.label}
                      control={form.control}
                      component="select"
                    />
                  </div>
                )
              case 'text':
              case 'email':
              case 'number':
                return (
                  <div
                    className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                    key={input.name}
                  >
                    <Text
                      className="text-[15px] text-gray-900 w-auto"
                      size="txtPoppinsMedium15"
                    >
                      {input.label}

                      <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                        *
                      </span>
                    </Text>
                    <Input
                      name={input.name}
                      placeholder={input.label}
                      className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
                      wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-2.5 pr-[35px] py-3.5 rounded-[9px] w-full"
                      control={form.control}
                      type={input.type}
                    />
                  </div>
                )
              case 'date':
                return (
                  <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full">
                    <Text
                      className="text-[15px] text-gray-900 w-auto"
                      size="txtPoppinsMedium16Black900"
                    >
                      {input.label}
                    </Text>
                    <Datepicker
                      className="px-[15px] py-[10px] border  rounded-[10px]  border-[#EAEAEA] bg-[#F9FBFF] w-full"
                      placeholder={input.placeholder}
                      control={form.control}
                      name={input.name}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>
                )
            }
          })}
        </div>
      </div>
    </ModalBase>
  )
}

export default AddVisitor
