import { createCandidateInput } from 'constant/inputInstance'
import ModalBase from 'modals/BaseModal'
import { Datepicker, Input, SelectBox, Text } from 'components'
import { useForm } from 'react-hook-form'
import { createCandidate as candidateValidation } from 'utils/validation'
import { yupResolver } from '@hookform/resolvers/yup'
import useCandidate from 'hooks/useCandidate'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import VerifyCandidate from 'modals/VerifyCandidate'

// import mrzScanner from 'mrz-scan'
const CreateCandidate = ({ children }) => {
  const { createCandidate } = useCandidate()

  const form = useForm({
    resolver: yupResolver(candidateValidation),
  })

  const candidateHandler = useMutation({
    mutationKey: ['createCandidate'],
    mutationFn: createCandidate,
    onSuccess: () => {
      form.reset()
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const onSubmit = async (data) => {
    await toast.promise(candidateHandler.mutateAsync(data), {
      loading: 'loading...',
      success: 'Candidate added',
      error: undefined,
    })
  }

  // useEffect(() => {
  //   const pass = async () => {
  //     const passportFile = form.getValues('passportfile')[0]
  //     if (passportFile) {
  //       const result = await mrzScanner(Buffer.from(passportFile, 'base64'))
  //       console.log(result)
  //     }
  //   }

  //   if (form.getValues('passportfile').length > 0) {
  //     pass()
  //   }
  // }, [form.watch('passportfile')])

  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[1500px]"
      acceptFunctions={form.handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <Text
            className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize"
            size="txtPoppinsMedium20Gray900"
          >
            Candiate Information
          </Text>
          <Text>Fill in the information below to create a new candidate</Text>
        </div>
        <p className="my-4 font-semibold font-['Poppins'] leading-tight">
          Before creating the candidate please check if exist or not by clciking{' '}
          <VerifyCandidate>
            <span className="text-blue-500 underline">here.</span>
          </VerifyCandidate>
        </p>
        <div className="flex w-full gap-3">
          {' '}
          {/* candidate infromation */}
          <form
            className="py-[30px] px-[20px] flex flex-col gap-[40px] rounded-[10px] bg-[#FFF] w-full h-auto "
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-[20px] ">
              {createCandidateInput.map((inputs, index) => (
                <div key={index}>
                  <div className="text-neutral-900 text-[15px] font-semibold font-['Poppins'] leading-tight py-4">
                    {inputs.title}
                  </div>
                  <div
                    className="grid gap-8 sm:grid-cols-1 gird-col-input"
                    key={inputs.title}
                  >
                    {inputs.inputs.map((input) => {
                      switch (input.type) {
                        case 'select':
                          return (
                            <div
                              className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                              key={input.name}
                            >
                              <Text
                                className="text-[15px] text-gray-900 w-auto"
                                size="txtPoppinsMedium15"
                              >
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                                    *
                                  </span>
                                )}
                              </Text>
                              <SelectBox
                                className="bg-white-A700 border border-indigo-400 border-solid  px-2.5 py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                                placeholderClassName="text-black-900_60"
                                indicator={
                                  <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                                    <img
                                      className="h-5 w-5 rounded-[50%]"
                                      src="/images/img_uangledown.svg"
                                      alt="u:angle-down"
                                    />
                                  </div>
                                }
                                name={input.name}
                                options={input.option}
                                isSearchable={true}
                                placeholder={input.label}
                                control={form.control}
                                component="select"
                              />
                            </div>
                          )
                        case 'text':
                        case 'email':
                        case 'number':
                          return (
                            <div
                              className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                              key={input.name}
                            >
                              <Text
                                className="text-[15px] text-gray-900 w-auto"
                                size="txtPoppinsMedium15"
                              >
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                                    *
                                  </span>
                                )}
                              </Text>
                              <Input
                                name={input.name}
                                placeholder={input.label}
                                className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
                                wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-2.5 pr-[35px] py-3.5 rounded-[9px] w-full"
                                control={form.control}
                                type={input.type}
                              />
                            </div>
                          )
                        case 'date':
                          return (
                            <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full">
                              <Text
                                className="text-[15px] text-gray-900 w-auto"
                                size="txtPoppinsMedium16Black900"
                              >
                                {input.label}
                              </Text>
                              <Datepicker
                                className="px-[15px] py-[10px] border  border-solid rounded-[10px]  border-indigo-400 w-full"
                                placeholder="DD/MM/YYYY"
                                control={form.control}
                                name={input.name}
                              ></Datepicker>
                            </div>
                          )
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </form>
          {/* passport scan */}
          <div className=" border border-indigo-400 border-solid max-h-[400px] w-full grid place-items-center min-w-[500px]  rounded-md relative cursor-pointer">
            <div>
              <img
                src="images/scanner.png"
                alt="scanner"
                className="w-full h-full max-w-[100px] my-3"
              />
              <span className="font-semibold">Passport Image</span>
              <input
                type="file"
                className="absolute top-0 right-0 w-full h-full opacity-0"
                {...form.register('passportfile' as never)}
                placeholder="Passport Image"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export default CreateCandidate
