import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './styles/tailwind.css'
import './styles/color.css'
import './styles/font.css'
import './styles/index.css'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { OrderProvider } from 'context/orderContext'
import { HiringProvider } from 'context/HriringContext'
import { PostRecurtmentProvider } from 'context/PostRecurtmentContext'
import { SourcingProvider } from 'context/SourcingContext'
import { BrowserRouter } from 'react-router-dom'
import { register } from 'config/firebase'
import markerSDK from '@marker.io/browser'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, { response }: any) {
        if (response?.status === 400) return false
        if (response?.status === 404) return false
        else if (failureCount < 2) return true
        else return false
      },
    },
  },
})

Sentry.init({
  dsn: 'https://11a05dd0914547cb8c530c5d1d1a1f7f@o4507011191078912.ingest.us.sentry.io/4507011192848384',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/yourserver\.io\/api/,
    /.*\.app\.github\.dev/,
    'https://acs.levitation.in',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const rootElement = document.getElementById('root')

async function loadMarkerWidget() {
  const widget = await markerSDK.loadWidget({
    project: '666bee7c998f39642f28873e',
  });
}

loadMarkerWidget();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <OrderProvider>
          <HiringProvider>
            <PostRecurtmentProvider>
              <SourcingProvider>
                <App />
              </SourcingProvider>
            </PostRecurtmentProvider>
          </HiringProvider>
        </OrderProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement,
)

register()
