import React, { MouseEventHandler } from 'react'
import { cn } from 'utils'

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'onClick'
> &
  Partial<{
    className: string

    leftIcon: React.ReactNode
    rightIcon: React.ReactNode
    onClick: MouseEventHandler<HTMLButtonElement>
  }>

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={cn(
        'disabled:opacity-80 disabled:cursor-not-allowed',
        className,
      )}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}

export { Button }
