import React from 'react'

import { Img, Text } from 'components'

type PlusButtonProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'projectname' | 'onClick'
> &
  Partial<{
    projectname: string
    onClick: React.MouseEventHandler<HTMLElement>
  }>

const PlusButton: React.FC<PlusButtonProps> = (props) => {
  return (
    <>
      <div className={props.className} onClick={props?.onClick}>
        <Img
          className="w-6 h-6"
          src="/images/img_plus_blue_a200.svg"
          alt="plus"
        />
        {props.projectname ||
          (props.title && (
            <Text
              className="text-[15px] text-gray-900_01 w-auto"
              size="txtPoppinsRegular15Gray90001"
            >
              {props?.projectname || props?.title}
            </Text>
          ))}
      </div>
    </>
  )
}

export default PlusButton
