import ModalBase from 'modals/BaseModal'
import { Text } from 'components'
import { ReactNode } from 'react'
import { useMutation } from 'react-query'
import usePostRecurtment, { stages } from 'hooks/usePostRecurtment'
import { useForm } from 'react-hook-form'
import usePostRecurtmentContext from 'context/PostRecurtmentContext'
import toast from 'react-hot-toast'

interface flyoutCheckList {
  children: ReactNode
  stages: stages[]
  defaultValues?: object
}

const FlyoutCheckList = ({
  children,
  stages,
  defaultValues,
}: flyoutCheckList) => {
  const { currentApplicant } = usePostRecurtmentContext();
  const { updateFlyoutCheckList } = usePostRecurtment();
  const form = useForm({
    defaultValues,
  });

  const checkListHandler = useMutation({
    mutationKey: ['flyoutCheckList'],
    mutationFn: updateFlyoutCheckList,
    onError: (error: any) => {
      toast.dismiss();
      toast.error(error?.response?.data?.message);
    },
    onSuccess: () => {
      toast.dismiss();
    },
    onMutate: () => {
      toast.loading('Updating the checklist...');
    },
  });

  const onsubmit = (data) => {
    const stages = Object.entries(data).map(([key, value]) => ({
      stage: key,
      value: Boolean(value),
    }));

    const allStagesTrue = stages.every(stage => stage.value === true);

    const newData = {
      applicationId: currentApplicant.applicationid,
      stages,
    };

    checkListHandler.mutate(newData, {
      onSuccess: () => {
        if (allStagesTrue) {
          toast.success('Candidate is ready for departure');
        } else {
          toast.success('checklist has been updated');
        }
      },
    });
  };

  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[900px]"
      acceptFunctions={form.handleSubmit(onsubmit)}
    >
      <Text
        className="w-auto text-xl text-gray-900 capitalize"
        size="txtPoppinsMedium20Gray900"
      >
        Flyout Checklist
      </Text>
      <Text size="txtPoppinsRegular11Black900" className="text-gray-500">
        Please review and select the documents received by the candidate.
      </Text>
      <div className="w-full ">
        {stages.map(({ stage, flyout_checklist }) => (
          <div
            className="flex items-center w-full gap-3 p-3 border cursor-pointer"
            key={stage}
          >
            <input
              name={stage}
              {...form.register(stage as never)}
              type="checkbox"
              defaultChecked={!!flyout_checklist}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <Text>{stage}</Text>
          </div>
        ))}
      </div>
    </ModalBase>
  );
};

export default FlyoutCheckList;
