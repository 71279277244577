import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const handleSectionNavigation = (id: string) => {
  const element = document.getElementById(id)
  const offset = 45
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element?.getBoundingClientRect().top ?? 0
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

export const handleSelectAllChange = (event, tableData, setSelectedIds) => {
  const checked = event.target.checked
  const allIds = tableData.current.map((item) => item.id)

  // Update the selectedIds array based on the "Select All" checkbox
  if (checked) {
    setSelectedIds(allIds)
  } else {
    setSelectedIds([])
  }
}

export const handleCheckboxChange = (id, setSelectedIds) => {
  // Toggle the selection state of the individual checkbox
  setSelectedIds((prevSelectedIds) => {
    if (prevSelectedIds.includes(id)) {
      // If the ID is already in the array, remove it
      return prevSelectedIds.filter((selectedId) => selectedId !== id)
    } else {
      // If the ID is not in the array, add it
      return [...prevSelectedIds, id]
    }
  })
}

export const statuscolor = (status: string) => {
  switch (status?.toLocaleLowerCase()) {
    case 'active':
      return {
        text: 'text-emerald-700',
        bg: 'bg-emerald-500',
        bg2: 'bg-emerald-50',
      }
    case 'inactive':
    case 'cancelled':
    case 'decline':
      return {
        text: 'text-red-500',
        bg: 'bg-red-500',
        bg2: 'bg-red-50',
      }
    case 'ongoing':
      return {
        text: 'text-orange-500',
        bg: 'bg-orange-500',
        bg2: 'bg-orange-50',
      }
    case 'archive':
    case 'draft':
      return {
        text: 'text-purple-500',
        bg: 'bg-purple-500',
        bg2: 'bg-purple-50',
      }
    default:
      return {} // Handle other cases or return a default value
  }
}

export const Pagination = ({ currPage, pageCount, onPageChange }) => {
  const generatePageNumbers = () => {
    const pages = []
    const totalPages = pageCount

    // Show first three pages
    for (let i = 1; i <= 3 && i <= totalPages; i++) {
      pages.push(
        <button
          type="button"
          key={i}
          className={`w-[40px] h-[40px]   rounded-md ${
            currPage === i
              ? 'bg-[#EFF1F4] text-[#091E42] font-[600]'
              : 'bg-white text-gray-700'
          }`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>,
      )
    }

    // Show ellipsis if there are more than 3 pages before the current page
    if (currPage > 4) {
      pages.push(
        <span
          key="ellipsis-before"
          className="h-[40px] w-[40px] flex justify-center items-center text-gray-700"
        >
          ...
        </span>,
      )
    }

    // Show three pages before and after the current page
    for (let i = currPage - 1; i <= currPage + 1; i++) {
      if (i > 3 && i < totalPages - 2) {
        pages.push(
          <button
            type="button"
            key={i}
            className={`w-[40px] h-[40px]   rounded-md ${
              currPage === i
                ? 'bg-[#EFF1F4] text-[#091E42] font-[600]'
                : 'bg-white text-gray-700'
            }`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>,
        )
      }
    }

    // Show ellipsis if there are more than 3 pages after the current page
    if (currPage < totalPages - 3) {
      pages.push(
        <span
          key="ellipsis-after"
          className="h-[40px] w-[40px] flex justify-center items-center text-gray-700"
        >
          ...
        </span>,
      )
    }

    // Show last three pages
    for (let i = totalPages - 2; i <= totalPages && i > 3; i++) {
      pages.push(
        <button
          type="button"
          key={i}
          className={`w-[40px] h-[40px]   rounded-md ${
            currPage === i
              ? 'bg-[#EFF1F4] text-[#091E42] font-[600]'
              : 'bg-white text-gray-700'
          }`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>,
      )
    }

    return pages
  }

  return <div className="flex">{generatePageNumbers()}</div>
}

export const sortArrayByAttribute = (array, attributeName) => {
  return [...array].sort((a, b) => {
    const valueA = a[attributeName]?.toLowerCase()
    const valueB = b[attributeName]?.toLowerCase()
    if (valueA < valueB) {
      return -1
    }
    if (valueA > valueB) {
      return 1
    }
    return 0
  })
}

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export const dateFormat = (date: Date) => {
  const format = new Intl.DateTimeFormat('en-IN', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return format.format(new Date(date))
}
