import { initializeApp } from '@firebase/app'
import { getMessaging, getToken } from '@firebase/messaging'
import useNotification from 'hooks/useNotification'
import toast from 'react-hot-toast'
const firebaseConfig = {
  apiKey: 'AIzaSyBbBTE_7ulFxfbZLsNx7y-7pC8JDFo-uys',
  authDomain: 'acsnotify-6272a.firebaseapp.com',
  projectId: 'acsnotify-6272a',
  storageBucket: 'acsnotify-6272a.appspot.com',
  messagingSenderId: '533026179844',
  appId: '1:533026179844:web:75b0bebf31fada78561e51',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)
const { saveFmcToken } = useNotification()
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      console.log('Notification permission granted.')
      // Get the FCM token
      const token = await getToken(messaging)
      await saveFmcToken(token)
    } else {
      toast.error(
        'Notification permission denied, please allow notifications to get lastest notifications.',
      )
    }
    // // Handle foreground notifications
    // onMessage(messaging, (payload) => {
    //   console.log('Foreground Message:', payload)
    //   // Handle the notification or update your UI
    //   toast(`${payload.notification.title}.\n${payload.notification.body}`, {})
    // })
  } catch (error) {
    console.error('Error setting up notifications:', error)
  }
}

const register = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
      })
      .catch((error) => {
        console.error('Error registering service worker:', error)
      })
  }
}

export { messaging, setupNotifications, register }
