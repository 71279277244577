import { default as ModalProvider } from 'react-modal'
import { Button, Text } from 'components'
import React, { useEffect, useState } from 'react'

interface warningModalProps {
  children: React.ReactNode
  warningText: string
  title: string
  acceptTitle?: string
  acceptFunction?: () => void
  defaultOpen?: boolean
  ref?: React.RefObject<HTMLButtonElement>
}

const WarningModal = ({
  children,
  warningText,
  title,
  acceptFunction,
  acceptTitle,
  defaultOpen = false,
  ref,
}: warningModalProps) => {
  const [open, setOpen] = useState(false)
  const handleModalOpen = () => setOpen(!open)

  useEffect(() => {
    console.log(defaultOpen)
    if (defaultOpen) {
      setOpen(true)
    }
  }, [defaultOpen])

  return (
    <>
      <button onClick={handleModalOpen} type="button" ref={ref}>
        {children}
      </button>
      <ModalProvider
        appElement={document.getElementById('root')}
        className="m-auto"
        overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full"
        isOpen={open}
        onRequestClose={handleModalOpen}
      >
        <div className="max-h-[97vh] overflow-y-auto sm:w-full md:w-full">
          <div className="bg-white-A700 flex flex-col gap-[25px]  justify-center p-10 md:px-5 rounded-[15px] w-[500px]">
            <Text
              className="w-auto text-xl text-red-600 capitalize"
              size="txtPoppinsMedium20Red600"
            >
              {title}
            </Text>
            <div className="flex flex-col gap-[25px] items-center justify-start w-full">
              <Text
                className="leading-[32.00px] text-base  text-gray-700 w-full"
                size="txtPoppinsRegular16Gray700"
              >
                {warningText}
              </Text>
              <div className="flex flex-row gap-[25px] items-center  pt-2.5 w-full">
                <Button
                  className=" border-[3px] border-red-500 border-solid cursor-pointer h-[35px] min-w-[103px]  rounded-[5px] text-sm text-white-A700 capitalize bg-red-500"
                  type="button"
                  onClick={() => {
                    if (typeof acceptFunction == 'function') acceptFunction()
                    handleModalOpen()
                  }}
                >
                  {acceptTitle || 'accept'}
                </Button>
                <Text
                  className="w-auto text-base text-center text-gray-700 cursor-pointer"
                  size="txtPoppinsRegular16Gray700"
                  onClick={handleModalOpen}
                >
                  cancel
                </Text>
              </div>
            </div>
          </div>
        </div>
      </ModalProvider>
    </>
  )
}

export default WarningModal
