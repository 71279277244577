import { useContext, createContext, useState } from 'react'
import useSourcing, {
  searchForSourcing,
  SourceHistory,
} from 'hooks/useSourcing'
import { useMutation, UseMutationResult } from 'react-query'
import { Candidate } from 'hooks/useCandidate'
import toast from 'react-hot-toast'
interface sourcingContext {
  sourcingSearchHandler: UseMutationResult<
    any,
    unknown,
    searchForSourcing,
    unknown
  >
  setCurrentCandidate: (value: object) => void
  currentCandidate: Candidate
  currentOutreach: SourceHistory
  setCurrentOutreach: (value: SourceHistory) => void
}

export const SourcingContext = createContext<sourcingContext>(null)

export const SourcingProvider = ({ children }) => {
  const { searchCandidatesForSourcing } = useSourcing()
  //sourcing handler
  const sourcingSearchHandler = useMutation({
    mutationKey: ['sourcingSearchHandler'],
    mutationFn: searchCandidatesForSourcing,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const [currentCandidate, setCurrentCandidate] = useState<Candidate>(null)
  const [currentOutreach, setCurrentOutreach] = useState<SourceHistory>(null)
  return (
    <SourcingContext.Provider
      value={{
        sourcingSearchHandler,
        setCurrentCandidate,
        currentCandidate,
        setCurrentOutreach,
        currentOutreach,
      }}
    >
      {children}
    </SourcingContext.Provider>
  )
}

const useSourcingContext = () => useContext(SourcingContext)

export default useSourcingContext
