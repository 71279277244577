import React from 'react'
import { cn } from 'utils'

const sizeClasses = {
  txtPoppinsRegular14BlueA200: 'font-normal font-poppins',
  txtRobotoRomanMedium10: 'font-medium font-roboto',
  txtInterSemiBold1074Deeporange900: 'font-inter font-semibold',
  txtPoppinsMedium15BlueA200: 'font-medium font-poppins',
  txtPoppinsMedium20Gray90001: 'font-medium font-poppins',
  txtRobotoRomanSemiBold16: 'font-roboto font-semibold',
  txtPoppinsMedium20Gray90004: 'font-medium font-poppins',
  txtPoppinsSemiBold2299: 'font-poppins font-semibold',
  txtPoppinsSemiBold13: 'font-poppins font-semibold',
  txtPoppinsRegular20Bluegray90001: 'font-normal font-poppins',
  txtPoppinsRegular20Gray70001: 'font-normal font-poppins',
  txtPoppinsSemiBold16: 'font-poppins font-semibold',
  txtMontserratRomanSemiBold32: 'font-montserrat font-semibold',
  txtInterMedium2685: 'font-inter font-medium',
  txtPoppinsRegular20WhiteA700: 'font-normal font-poppins',
  txtPoppinsRegular1916: 'font-normal font-poppins',
  txtInterRegular2685: 'font-inter font-normal',
  txtInterMedium1074Deeporange900: 'font-inter font-medium',
  txtPoppinsMedium20Red600: 'font-medium font-poppins',
  txtRobotoRomanRegular14BlueA200: 'font-normal font-roboto',
  txtPoppinsRegular14WhiteA700: 'font-normal font-poppins',
  txtPoppinsRegular11WhiteA700: 'font-normal font-poppins',
  txtPoppinsMedium15Black900dd: 'font-medium font-poppins',
  txtInterSemiBold1074: 'font-inter font-semibold',
  txtInterSemiBold1074Teal800: 'font-inter font-semibold',
  txtInterMedium12: 'font-inter font-medium',
  txtInterMedium1074: 'font-inter font-medium',
  txtPoppinsRegular14: 'font-normal font-poppins',
  txtPoppinsRegular15: 'font-normal font-poppins',
  txtMontserratRomanRegular1584: 'font-montserrat font-normal',
  txtPoppinsMedium15Black900: 'font-medium font-poppins',
  txtPoppinsRegular16: 'font-normal font-poppins',
  txtPoppinsSemiBold1533: 'font-poppins font-semibold',
  txtPoppinsMedium24: 'font-medium font-poppins',
  txtInterMedium1074Deeppurple500: 'font-inter font-medium',
  txtRobotoRomanRegular16: 'font-normal font-roboto',
  txtPoppinsRegular14Gray90001: 'font-normal font-poppins',
  txtPoppinsRegular14Gray900: 'font-normal font-poppins',
  txtPoppinsMedium20: 'font-medium font-poppins',
  txtInterMedium1074Red800: 'font-inter font-medium',
  txtPoppinsRegular16Gray700: 'font-normal font-poppins',
  txtInterMedium1023: 'font-inter font-medium',
  txtPoppinsRegular20: 'font-normal font-poppins',
  txtPoppinsRegular20Gray90001: 'font-normal font-poppins',
  txtPoppinsMedium14Green500: 'font-medium font-poppins',
  txtPoppinsMedium28: 'font-medium font-poppins',
  txtRobotoRomanRegular14: 'font-normal font-roboto',
  txtRobotoRomanSemiBold14Blue700: 'font-roboto font-semibold',
  txtPoppinsRegular15Gray90001: 'font-normal font-poppins',
  txtPoppinsMedium20Gray900: 'font-medium font-poppins',
  txtPoppinsLight16: 'font-light font-poppins',
  txtInterMedium1074Teal800: 'font-inter font-medium',
  txtPoppinsMedium16BlueA200: 'font-medium font-poppins',
  txtInterMedium1023WhiteA700: 'font-inter font-medium',
  txtPoppinsRegular11: 'font-normal font-poppins',
  txtPoppinsMedium14: 'font-medium font-poppins',
  txtPoppinsRegular1533: 'font-normal font-poppins',
  txtPoppinsRegular12: 'font-normal font-poppins',
  txtPoppinsMedium20Bluegray90001: 'font-medium font-poppins',
  txtPoppinsMedium16Black900: 'font-medium font-poppins',
  txtPoppinsRegular13: 'font-normal font-poppins',
  txtPoppinsRegular12Black90001: 'font-normal font-poppins',
  txtPoppinsMedium15Gray90001: 'font-medium font-poppins',
  txtPoppinsMedium10: 'font-medium font-poppins',
  txtPoppinsRegular15Bluegray90001: 'font-normal font-poppins',
  txtInterSemiBold1074Deeppurple500: 'font-inter font-semibold',
  txtPoppinsMedium18: 'font-medium font-poppins',
  txtPoppinsMedium15: 'font-medium font-poppins',
  txtPoppinsMedium14Black900dd: 'font-medium font-poppins',
  txtPoppinsMedium16: 'font-medium font-poppins',
  txtInterMedium12BlueA200: 'font-inter font-medium',
  txtInterRegular1164: 'font-inter font-normal',
  txtInterBold895: 'font-bold font-inter',
  txtPoppinsRegular12Green500: 'font-normal font-poppins',
  txtPoppinsMedium20BlueA200: 'font-medium font-poppins',
  txtInterMedium1074BlueA200: 'font-inter font-medium',
  txtPoppinsRegular20BlueA200: 'font-normal font-poppins',
  txtPoppinsRegular11Black900: 'font-normal font-poppins',
  txtPoppinsMedium20Gray70001: 'font-medium font-poppins',
  txtPoppinsSemiBold1916: 'font-poppins font-semibold',
  txtPoppinsMedium14Gray10005: 'font-medium font-poppins',
  txtInterBold1023: 'font-bold font-inter',
  txtPoppinsMedium18Gray900: 'font-medium font-poppins',
  txtPoppinsMedium16Bluegray90001: 'font-medium font-poppins',
  txtRobotoRomanRegular16Gray90001: 'font-normal font-roboto',
  txtPoppinsMedium15Bluegray90001: 'font-medium font-poppins',
  txtPoppinsMedium16Bluegray90003: 'font-medium font-poppins',
  txtRobotoRomanSemiBold14: 'font-roboto font-semibold',
  txtInterMedium1969: 'font-inter font-medium',
  txtInterSemiBold1074Red800: 'font-inter font-semibold',
  txtRobotoRomanSemiBold14BlueA200: 'font-roboto font-semibold',
  txtPoppinsMedium14Bluegray90003: 'font-medium font-poppins',
} as const

export type TextProps = Partial<{
  className: string
  size: keyof typeof sizeClasses
  as: React.ElementType
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = '',
  size,
  as,
  ...restProps
}) => {
  const capitalizeFirstLetter = (text: string) => {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const Component = as || 'p'

  return (
    <Component
      className={cn(className, size && sizeClasses[size])}
      {...restProps}
    >
      {typeof children === 'string'
        ? capitalizeFirstLetter(children)
        : children}
    </Component>
  )
}

export { Text }
