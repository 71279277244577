import React from 'react'
import { ErrorMessage } from '../../components/ErrorMessage'

export type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'size' | 'prefix' | 'type' | 'onChange'
> &
  Partial<{
    className: string
    name: string
    placeholder: string
    errors: string[]
    onChange: Function
  }>

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = '',
      name = '',
      placeholder = '',
      children,
      onChange,

      errors,
      ...restProps
    },
    ref,
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      if (onChange) onChange(e?.target?.value)
    }

    return (
      <>
        <textarea
          ref={ref}
          className={`${className}`}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          {...restProps}
        />
        {!!errors && <ErrorMessage errors={[errors]} />}

        {children}
      </>
    )
  },
)

export { TextArea }
