import { baseURL, apis } from 'service'
import Cookies from 'universal-cookie'
import useclient from './useClient'
import { Candidate } from 'hooks/useCandidate'
import { getBase64 } from 'helper'
import { promises } from 'dns'
export type createPostRecurtmentProps = {
  pipeline_id: string
  job_id: string
  country: string
  end_date: string
  PTA: boolean
  isGAMACrquired: boolean
  isDemandedAvaliable: boolean
  travelAdvisoryDoc: File
  joiningTicketDoc: File
}

export type stageFieldProp = {
  country: string
  stage: string
}

export interface FormField {
  country: string
  stage: string
  field_label: string
  field_name: string
  field_type: 'text' | 'file' | 'number' | 'option' | 'date'
  field_placeholder: string
  field_options: string[]
}

export interface applicantList {
  application_id: string
  candidate_id: string
  candidate_name: string
  outreach_medium: string
}

export interface applicantRefrence {
  applicationid: string
  candidateid: string
}

export interface stages {
  stage: string
  status: string
  data: object
  flyout_checklist: string
}
export interface applicationDetail {
  stages: stages[]
  candidate: Candidate
  flyout_details: object
}

export interface applicantFiles {
  applicationId: string
  stage: string
  status: string
  data: object
}
export interface checklist {
  stage: string
  value: boolean
}
export interface flyoutCheckList {
  applicationId: string
  stages: checklist[]
}
export interface ApplicantApproveOrReject {
  application_id: string
  stage: string
  approveStatus: string
}
interface PostRecruitmentDashboard {
  project: {
    _id: string
    name: string
    project_id: string
  }
  client: {
    name: string
    _id: string
  }
  job: {
    title: string
    _id: string
  }
  end_date: string
  total_candidates: number
  total_medical_documents: number
  total_visa_documents: number
  _id: string
}

const usePostRecurtment = () => {
  const { getAuthHeader } = useclient()
  const cookies = new Cookies()

  const getPostRecruitmentList = async (
    params,
  ): Promise<{
    data: PostRecruitmentDashboard[]
    paginator: any
  }> => {
    try {
      const response = await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/getallprocessdetails`,
        {
          headers: getAuthHeader(),
          data: params,
        },
      )

      return response.data.data
    } catch (error) {
      console.error('Error fetching the post recruitment list', error)
      throw error
    }
  }

  const createPostRecurtment = async (
    PostRecurtmentFile: createPostRecurtmentProps,
  ) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/createProcess`,
        {
          data: PostRecurtmentFile,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + cookies.get('authToken'),
          },
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getfieldWithStageName = async (
    fieldOption: stageFieldProp,
  ): Promise<FormField[]> => {
    try {
      const response = await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/getCountryDoc`,
        {
          data: fieldOption,
          headers: getAuthHeader(),
        },
      )
      const formData: FormField[] = response.data.data
      return formData
    } catch (error) {
      console.error('Error fetching field with stage name:', error.message)
      throw error
    }
  }

  const getApplicantList = async (
    PostRecurtmentProcessId: string,
  ): Promise<{
    candidates: applicantList[]
    stats: any
  }> => {
    try {
      const response = await apis.get(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/candidates/${PostRecurtmentProcessId}`,
        {
          headers: getAuthHeader(),
        },
      )
      const list: {
        candidates: applicantList[]
        stats: any
      } = response.data?.data
      return list
    } catch (error) {
      console.error('Error getting the list of the applicant ', error)
      throw error
    }
  }

  const getApplicantData = async (
    applicantRefrence: applicantRefrence,
  ): Promise<applicationDetail> => {
    try {
      const response = await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/candidatedetails`,
        {
          data: applicantRefrence,
          headers: getAuthHeader(),
        },
      )
      if (!response.data?.data) {
        throw new Error('Could not get the application detail')
      }
      return response.data.data
    } catch (error) {
      console.error('Error getting the single applicant data', error)
      throw error
    }
  }

  const uploadApplicantFile = async (applicantDocument: applicantFiles) => {
    try {
      // converting the file into the base64 encoded string.
      for (const [key, value] of Object.entries(applicantDocument.data)) {
        if (Array.isArray(value)) {
          //assuming if the vlaue is array then it must be file array
          const base64Array = await Promise.all(
            value.map(async (file: File) => {
              return await getBase64(file)
            }),
          )
          applicantDocument.data[key] = base64Array
        } else if (typeof value == 'object' && value instanceof File) {
          applicantDocument.data[key] = await getBase64(value)
        }
      }

      console.log(applicantDocument)
      await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/uploadrecruiterdocs`,
        {
          data: applicantDocument,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('Error uploading the applicant data', error)
      throw error
    }
  }

  const updateFlyoutCheckList = async (checklist: flyoutCheckList) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/updateflyout`,
        {
          data: checklist,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('Error in Updating the applicant checklist', error)
      throw error
    }
  }

  const ApplicantApproveOrReject = async (data: ApplicantApproveOrReject) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/candidate/addremark`, {
        data: data,
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error('Error in reject the applicant', error)
      throw error
    }
  }

  const createInputField = async (data: FormField) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/createCountryDoc`,
        {
          data: data,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('Error in reject the applicant', error)
      throw error
    }
  }
  const getstagesByCountry = async (country: string) => {
    try {
      const response = await apis.get(
        `${baseURL}/recruiter/api/v1/visaAndPostHiring/getstages/${country}`,
        {
          headers: getAuthHeader(),
        },
      )
      return response.data?.data
    } catch (error) {
      console.error('Error in reject the applicant', error)
      throw error
    }
  }

  const updatePostRecurtmentStatus = async (data) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/updatepostrecruitmentstatus`,
        {
          data: data,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('failed to update the candidate status', error)
      throw error
    }
  }

  return {
    getPostRecruitmentList,
    createPostRecurtment,
    getfieldWithStageName,
    getApplicantList,
    getApplicantData,
    uploadApplicantFile,
    updateFlyoutCheckList,
    ApplicantApproveOrReject,
    createInputField,
    getstagesByCountry,
    updatePostRecurtmentStatus,
  }
}

export default usePostRecurtment
