import React from 'react'
import { Text } from '../../components'

type StatsBoxProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'title' | 'value'
> &
  Partial<{ title: string; value: string }>

const StatsBox: React.FC<StatsBoxProps> = React.memo((props) => {
  return (
    <>
      <div className={props.className}>
        <Text className="w-auto text-sm font-medium text-gray-900 font-poppins">
          {props?.title}
        </Text>
        <Text className="font-medium font-poppins sm:text-2xl md:text-[26px] text-blue-A200 text-lg w-auto">
          {props?.value}
        </Text>
      </div>
    </>
  )
})

StatsBox.defaultProps = { title: 'Stat Title', value: 'NA' }

export default StatsBox
