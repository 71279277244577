import { apis, baseURL } from 'service'
import useclient from './useClient'
import { useSearchParams } from 'react-router-dom'

const usePipeline = (type = 'hiring') => {
  const { getAuthHeader } = useclient()
  const [searchParams] = useSearchParams()
  const piplineType =
    searchParams.get('h') == 'hiring' ? 'hiring' : 'post recruitment'

  const getPlineList = async () => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/pipeline/list`,
        {
          data: {
            query: {
              pipeline_type: piplineType,
            },
            options: {
              pagination: false,
            },
          },
          headers: getAuthHeader(),
        },
      )

      if (!data.data) {
        return []
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getPipeline = async (pipelineId: string) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/pipeline/${pipelineId}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  const createPipeline = async (piplineData) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/pipeline/create`,
        {
          data: {
            ...piplineData,
            stages: piplineType === 'hiring' ? ['disqualified', 'sourced'] : [],
            pipeline_type: piplineType,
          },
          headers: getAuthHeader(),
        },
      )

      if (!data.data) {
        return []
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updatePipeline = async (updatedData) => {
    try {
      const { data } = await apis.put(
        `${baseURL}/recruiter/api/v1/pipeline/update/${updatedData.piplineId}`,
        {
          data: {
            ...updatedData,
          },
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return { getPlineList, createPipeline, getPipeline, updatePipeline }
}

export default usePipeline
