import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { postLogout } from 'service/Api/Login'
import Cookies from 'universal-cookie'
import Spinner from 'Common/spinner'
import useAuth from 'hooks/useAuth'

const cookies = new Cookies()

const index = ({ setShowLogoutConfirmation }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { setIsloggedIn } = useAuth()
  const handleonLogout = async () => {
    setLoading(true)
    const token = cookies.get('authToken')
    const req = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    postLogout(req)
      .then(() => {
        cookies.remove('authToken')
        cookies.remove('fcm')
        localStorage.removeItem('uid')
        setLoading(false)
        toast.success('Logout Successfull')
      })
      .then(() => {
        setIsloggedIn(false)
        navigate('/')
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  return (
    <>
      {loading && <Spinner />}
      <div
        style={{ backgroundColor: 'rgba(0, 0, 0, .6)' }}
        className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
      >
        <div className="w-[590px] sm:w-[300px] h-[193px] px-2.5 py-5 bg-[#FFF] rounded-xl flex-col justify-start items-start gap-5 inline-flex">
          <div className="h-[153px] flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch h-[74px] px-5 py-3 flex-col justify-start items-start gap-2 flex">
              <div className="text-slate-700 text-xl font-medium font-['Inter'] leading-tight">
                Log out ?
              </div>
              <div className="text-zinc-500 text-base font-normal font-['Open Sans']">
                Are you sure you want to log out ?
              </div>
            </div>
            <div className="self-stretch h-[59px] px-5 flex-col justify-start items-start gap-10 flex">
              <div className="py-2.5 justify-center items-center gap-5 inline-flex">
                <button
                  onClick={() => setShowLogoutConfirmation(false)}
                  className="px-[25px] py-[9px] bg-[#FFF] rounded-[9px] border border-zinc-100 justify-center items-center gap-2.5 flex"
                >
                  <div className="text-center text-neutral-900 text-[15px] font-medium font-['Poppins'] capitalize leading-tight">
                    Cancel
                  </div>
                </button>
                <button
                  onClick={handleonLogout}
                  className="px-[25px] py-[9px] text-[#FFF] bg-red-500 rounded-[9px] border justify-center items-center gap-2.5 flex"
                >
                  <div className="text-center textx-white text-[15px] font-medium font-['Poppins'] capitalize leading-tight">
                    Log out
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default index
