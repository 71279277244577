import { apis, baseURL } from 'service'

import { AxiosPromise, AxiosRequestConfig } from 'axios'

export type PostLogintype = Partial<{
  email: string
  password: string
}>

export const postLogin = (
  payload: AxiosRequestConfig<PostLogintype>,
): AxiosPromise => {
  return apis.post(`${baseURL}/recruiter/auth/login`, payload)
}

export type PostLogout = Partial<{
  headers: {
    Authorization: string
  }
}>

export const postLogout = (
  payload: AxiosRequestConfig<PostLogout>,
): AxiosPromise => {
  return apis.post(`${baseURL}/recruiter/auth/logout`, payload)
}
