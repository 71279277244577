import { useNavigate } from 'react-router-dom'
import { Button, Img, Input, Text } from 'components'
import { useForm } from 'react-hook-form'
import useAccount from 'hooks/useAccount'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'universal-cookie'
const SetNewPassword = () => {
  const { resetPassword } = useAccount()

  const cookie = new Cookies()
  const navigate = useNavigate()
  const formValidationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    newPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .min(8, 'New password must be at least 8 characters long')
      .required('New password is required'),
  })
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
  })

  const reset = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success(
        'Password reset successfully. Please login with your new password',
        {
          id: 'resetPassword',
        },
      )
      navigate('/')
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message, {
        id: 'resetPassword',
      })
    },
    onMutate: () => {
      toast.loading('Resetting password', {
        id: 'resetPassword',
      })
    },
  })

  const onSumbit = (data) => {
    reset.mutate({
      code: cookie.get('c'),
      newPassword: data.password,
    })
  }

  return (
    <div className="bg-blue-A200 min-h-[100vh] flex sm:flex-col md:flex-col flex-row sm:gap-10 md:gap-10 items-center justify-between md:justify-center mx-auto md:px-10 px-20 sm:px-5 w-auto sm:w-full md:w-full">
      <Img
        className="h-full w-[418px] md:hidden"
        src="images/img_image.svg"
        alt="image"
      />
      <div className="bg-white-A700 flex flex-col gap-[15px] items-center justify-center pb-20 pt-10 md:px-5 px-[30px] rounded-[75px] shadow-bs2 w-[547px] sm:w-full">
        <div className="flex flex-col font-montserrat gap-[15px] items-center justify-center w-full">
          <div className="h-[89px] md:h-[50px] w-20 sm:w-full flex items-center justify-center">
            <Img
              className="object-contain w-full h-full"
              src="images/img_logo.png"
              alt="logo"
            />
          </div>

          <Text
            className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto"
            size="txtMontserratRomanSemiBold32"
          >
            Ambe Consultancy Pvt. Ltd.
          </Text>
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-2 pt-5 font-poppins">
          <Text className="w-auto text-xl font-medium text-black-900">
            Set New Password
          </Text>
          <Text className="w-auto text-sm font-medium text-gray-900_a8">
            Must be at least 8 characters
          </Text>
        </div>

        <div className="flex flex-col font-poppins gap-[5px] items-start justify-start w-full pt-[10px]">
          <Text
            className="w-auto text-base font-medium text-black-900"
            size="txtPoppinsMedium20"
          >
            New Password
          </Text>
          <Input
            name="password"
            placeholder="*********"
            className="w-full p-0 text-base font-medium text-left md:h-auto placeholder:text-gray-900_99 sm:h-auto sm:pr-5 text-gray-900_99"
            wrapClassName="bg-white-A700 border-[1px] border-[#424647] border-solid flex pl-5 pr-[35px] py-[19px] rounded-[10px] w-full"
            control={form.control}
            type="password"
            autoComplete="new-password"
          ></Input>
        </div>
        <div className="flex flex-col font-poppins gap-[5px] items-start justify-start w-full pt-[10px]">
          <Text
            className="w-auto text-base font-medium text-black-900"
            size="txtPoppinsMedium20"
          >
            Confirm Password
          </Text>
          <Input
            name="newPassword"
            placeholder="*********"
            className="w-full p-0 text-base font-medium text-left md:h-auto placeholder:text-gray-900_99 sm:h-auto sm:pr-5 text-gray-900_99"
            wrapClassName="bg-white-A700 border-[1px] border-[#424647] border-solid flex pl-5 pr-[35px] py-[19px] rounded-[10px] w-full"
            type="passowrd"
            autoComplete="confirm-password"
            control={form.control}
          />
        </div>

        <Button
          className="common-pointer bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer font-poppins font-[500] py-[18px] rounded-[10px] text-center text-white-A700 text-[16px] w-full"
          onClick={form.handleSubmit(onSumbit)}
          type="submit"
        >
          Set New Password
        </Button>
        <div className="flex justify-start w-full">
          <div
            onClick={() => navigate('/')}
            className="cursor-pointer text-neutral-900 text-base font-medium font-['Poppins'] leading-loose"
          >
            Back to login
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetNewPassword
