import React from 'react'
import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { Img, Text } from '../../components'
import Logout from 'modals/Logout'

type Sidebar1Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{}>
type SideBarMenuType = {
  icon?: JSX.Element
  label: JSX.Element | string
  suffix?: JSX.Element
  href?: string
  target?: string
  active?: boolean
}[]

const Sidebar1: React.FC<Sidebar1Props> = (props) => {
  const { collapseSidebar, collapsed } = useProSidebar()
  const navigate = useNavigate()
  const location = useLocation()
  const [ShowLogoutConfirmation, setShowLogoutConfirmation] =
    React.useState(false)

  const sideBarMenu: SideBarMenuType = [
    {
      icon: (
        <Link to="/dashboard">
          <Img
            className="h-6 mb-0.5 w-6"
            src="/images/MenuItems.svg"
            alt="icon"
          />
        </Link>
      ),
      label: 'Dashboard',
      href: '/dashboard',
      active: window.location.pathname === '/dashboard',
    },
    {
      icon: (
        <Link to="/projects">
          <Img
            className="h-6 mb-0.5 w-6"
            src="/images/img_menuitems_white_a700.svg"
            alt="icon_One"
          />
        </Link>
      ),
      label: 'Project',
      href: '/projects',
      // active: ['/projects', '/selectionprocess', '/projects'].includes(
      //   location.pathname,
      // ),
      active: [
        '/projects',
        '/selectionprocess',
        '/projects',
        '/requirementoverview',
      ].some((route) => new RegExp(`^${route}(\/|$)`).test(location.pathname)),
    },
    {
      icon: (
        <Link to="/clients">
          <Img
            className="w-6 h-6"
            src="/images/img_menuitems_white_a700_24x24.svg"
            alt="icon_Two"
          />
        </Link>
      ),
      label: 'Clients',
      href: '/clients',
      active: window.location.pathname === '/clients',
    },
    {
      icon: (
        <Link to="/calander">
          <Img
            className="h-6 mb-[3px] w-6"
            src="/images/img_calendarcalendar.svg"
            alt="icon_Three"
          />
        </Link>
      ),
      label: 'My Calendar',
      href: '/calander',
      active: window.location.pathname === '/calander',
    },
    {
      icon: (
        <Link to="/postrecrutment">
          <Img
            className="w-6 h-6"
            src="/images/img_airplane.svg"
            alt="airplane"
          />
        </Link>
      ),
      label: 'Post-Recruitment',
      href: '/postrecrutment',
      active: window.location.pathname === '/postrecrutment',
    },
    {
      icon: (
        <Link to="/notification">
          <Img
            className="w-6 h-6"
            src="/images/img_menuitems_24x24.svg"
            alt="icon_Four"
          />
        </Link>
      ),
      label: 'Notification',
      href: '/notification',
      active: window.location.pathname === '/notification',
    },
    {
      icon: (
        <Link to="/setting">
          <Img
            className="h-6 mb-[3px] w-6"
            src="/images/img_settings.svg"
            alt="settings"
          />
        </Link>
      ),
      label: 'Settings',
      href: '/setting',
      active: window.location.pathname === '/setting',
    },
    {
      icon: (
        <Img
          className="h-6 mb-[3px] w-6"
          src="/images/img_clock.svg"
          alt="clock"
        />
      ),
      label: 'LogOut',
    },
  ]

  const handleLogoutClick = () => {
    // Show the logout confirmation modal
    setShowLogoutConfirmation(true)
  }

  return (
    <>
      {ShowLogoutConfirmation && (
        <Logout setShowLogoutConfirmation={setShowLogoutConfirmation} />
      )}

      <Sidebar
        defaultCollapsed={true}
        onMouseEnter={() => collapseSidebar(false)}
        onMouseLeave={() => collapseSidebar(true)}
        className={props.className}
        style={{ transition: 'width .5s ease-in-out' }}
      >
        <Menu
          menuItemStyles={{
            button: {
              padding: '5px ',
              gap: '10px',
              marginTop: '21px',
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '14px',
              fontFamily: 'Poppins',
              borderRadius: '5px',
              [`&:hover, &.ps-active`]: {
                backgroundColor: '#f19e38ff !important',
              },
            },
          }}
          className="flex flex-col items-center justify-start  pt-1 px-5 w-[82%]"
        >
          {sideBarMenu?.map((menu, i) => (
            <MenuItem
              onClick={menu.label === 'LogOut' ? handleLogoutClick : undefined}
              key={`sideBarMenuItem${i}`}
              active={menu.active}
              icon={collapsed && menu.icon}
            >
              {!collapsed && (
                <>
                  {menu.label === 'LogOut' ? (
                    <div className="flex w-[200px] flex-row gap-[10px] pl-[10px]">
                      {menu.icon} {menu.label}
                    </div>
                  ) : (
                    <Link to={menu.href}>
                      <div className="flex w-[200px] flex-row gap-[10px] pl-[10px] ">
                        {menu.icon} {menu.label}
                      </div>
                    </Link>
                  )}
                </>
              )}
            </MenuItem>
          ))}
        </Menu>
      </Sidebar>
    </>
  )
}

export default Sidebar1
