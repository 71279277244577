import { ErrorMessage } from 'components/ErrorMessage'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'

type Props = {
  allowMultiple?: boolean
  maxFiles?: number
  maxSize?: number
  name: string
  acceptedFileTypes?: string
  preview?: boolean
  className?: string
  onUpload: Function
  error?: any
  Placeholder?: React.ElementType<{ multiple?: boolean }>
  Thumbnail?: React.ElementType<{
    file: FileObject
    onRemove: (index: number) => void
    index: number
    key: number
  }>
}

interface FileObject extends Blob {
  name: string
  type: string
}

function getFileExtension(file: FileObject): string {
  const fileName = file.name
  const parts = fileName.split('.')
  return parts[parts.length - 1]
}

function validateImage(file: FileObject): boolean {
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']
  return file && validImageTypes.includes(file.type)
}

function removeElementAtIndex<T>(arr: T[], index: number): T[] {
  if (index < 0 || index >= arr.length) {
    return arr
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

type PreviewItemProps = {
  file: FileObject
  index: number
  onRemove: (index: number) => void
}

function PreviewItem({ file, index, onRemove }: PreviewItemProps): JSX.Element {
  const isImage = validateImage(file)
  const url = isImage ? URL.createObjectURL(file) : ''
  const ext = getFileExtension(file)

  const handleRemove = (e) => {
    e.preventDefault()
    onRemove(index)
  }

  return (
    <div className="w-full h-full rounded-md flex flex-col min-h-[fit-content] ">
      <div className="relative flex-grow w-full r h-3/4">
        {url && (
          <img
            src={url}
            alt="Preview"
            className="object-cover h-full max-h-[100px]"
          />
        )}
        <button
          type="button"
          className="absolute top-[9px] right-0 p-1 bg-white rounded-full hover:bg-gray-200 focus:bg-gray-200"
          onClick={handleRemove}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 11.414l3.536 3.536 1.414-1.414L11.414 10l3.536-3.536-1.414-1.414L10 8.586 6.464 5.05 5.05 6.464 8.586 10l-3.536 3.536 1.414 1.414L10 11.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <p className="my-3 text-sm font-medium text-gray-900 max-w-[100px] truncate mr-5">
        {file.name}
      </p>
    </div>
  )
}

function FileUpload({
  Placeholder,
  className,
  onUpload,
  name,
  Thumbnail = PreviewItem,
  allowMultiple = false,
  preview = false,
  maxFiles = 3,
  maxSize = 10 * 1024 * 1024,
  acceptedFileTypes = 'image/*',
  error,
}: Props): JSX.Element {
  const [files, setFiles] = React.useState<FileObject[]>([])
  const drop = React.useRef(null)

  React.useEffect(() => {
    drop.current?.addEventListener('dragover', handleDragOver)
    drop.current?.addEventListener('drop', handleDrop)

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver)
      drop.current?.removeEventListener('drop', handleDrop)
    }
  }, [])

  const validateAndUpload = (files: FileObject[]) => {
    const selectedFiles: FileObject[] = Array.from(files).filter(
      (file) => file?.size <= maxSize, // Filter out files larger than maxSize
    )

    if (selectedFiles.length !== files.length) {
      toast.error('File size exceeds maximum allowed size')
      throw new Error('File size exceeds maximum allowed size.')
    }

    if (selectedFiles.length > maxFiles) {
      selectedFiles.splice(0, selectedFiles.length - maxFiles)
    }

    setFiles(selectedFiles)
    onUpload(selectedFiles)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      validateAndUpload(files)
    }
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    validateAndUpload(Array.from(event.target.files as FileList))
  }

  const handleOnRemove = (index: number) => {
    const updatedFiles = removeElementAtIndex([...files], index)
    setFiles([...updatedFiles])
  }

  return (
    <div className="flex flex-col items-end w-full min-h-[48px]">
      <label
        ref={drop}
        htmlFor={name}
        className={`cursor-pointer ${className}`}
      >
        {files?.length == 0 ? (
          <>{Placeholder ? <Placeholder multiple={allowMultiple} /> : null}</>
        ) : null}
        <input
          id={name}
          name={name}
          type="file"
          accept={acceptedFileTypes}
          className="sr-only"
          onChange={handleFileInputChange}
          multiple={allowMultiple}
        />
        {preview && files.length > 0 && (
          <div className="w-full h-full">
            <div className="w-full h-full gap-x-1">
              {files.map((file, index) => (
                <Thumbnail
                  file={file}
                  key={index}
                  onRemove={handleOnRemove}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
      </label>
      {error?.message && (
        <ErrorMessage errors={[error?.message]} className="mt-4 text-right" />
      )}
    </div>
  )
}

FileUpload.PreviewItem = PreviewItem
export { FileUpload }
