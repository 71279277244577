import { apis, baseURL } from 'service'
import Cookies from 'universal-cookie'

const useclient = () => {
  const cookies = new Cookies()
  const getAuthHeader = () => {
    return {
      Authorization: 'Bearer ' + cookies.get('authToken'),
    }
  }
  const createCompany = async (params: { id: string; updateData: object }) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/client/create`, //clinet
        {
          data: params.updateData,
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getCompany = async (id: string) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/client/${id}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw new Error('Failed to fetch the client')
    }
  }

  const getAllCompany = async (params) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/client/list`,

        {
          data: params,
          headers: getAuthHeader(),
        },
      )

      if (data.data === null) {
        return []
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw new Error('Failed to fetch the client')
    }
  }

  const updateCompany = async (params: { id: string; updateData: object }) => {
    // id = clientId
    try {
      await apis.put(`${baseURL}/recruiter/api/v1/client/update/${params.id}`, {
        data: params.updateData,
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteClient = async (id: string) => {
    try {
      const { data } = await apis.delete(
        `${baseURL}/recruiter/api/v1/company/partial-update/${id}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.message || 'Company with the ID deleted successfully'
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update company.')
    }
  }

  const getClientContact = async (clientId: string) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/contacts/${clientId}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('getClientContact', error)
      throw error
    }
  }

  const createClientContact = async (updateData: object) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/contact/create`, //client contact
        {
          data: updateData,
          headers: getAuthHeader(),
        },
      )
      return data.message || 'client contact created successfully'
    } catch (error) {
      console.error('createClientContact', error)
      throw error
    }
  }

  const updateClientContact = async (updateData: object) => {
    try {
      const { data } = await apis.put(
        `${baseURL}/recruiter/api/v1/contact/updateBulk`,
        {
          data: {
            contacts: updateData,
          },
          headers: getAuthHeader(),
        },
      )
      return data.message || 'client contact updated successfully'
    } catch (error) {
      console.error('updateClientContact', error)
      throw error
    }
  }

  const deleteClientContact = async (id: string[]) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/contact/deleteMany`,
        {
          data: {
            ids: id,
          },
          headers: getAuthHeader(),
        },
      )
      return data.message || 'client contact deleted successfully'
    } catch (error) {
      console.error('deleteClientContact', error)
      throw error
    }
  }

  return {
    getAllCompany,
    createCompany,
    getCompany,
    updateCompany,
    deleteClient,
    getAuthHeader,
    getClientContact,
    createClientContact,
    updateClientContact,
    deleteClientContact,
  }
}

export default useclient
