import NotFound from 'components/NotFound'
import SourcingResultCvview from 'components/SourcingResultCvview'

const CandidateOverview = ({ loading, cv }) => {
  if (!cv) {
    return (
      <div className="grid w-full place-items-center">
        <NotFound
          title="No CV found"
          subtitle="cv might not be available for this candidate"
        />
      </div>
    )
  }

  if (loading) {
    return <div>loading the data....</div>
  }
  return (
    <div>
      <SourcingResultCvview
        className="border border-gray-200 border-solid flex flex-col gap-2.5 items-start justify-start rounded-[10px] w-full"
        link={cv}
      />
    </div>
  )
}

export default CandidateOverview
