import { apis, baseURL } from 'service'
import useclient from './useClient'

function useNotification() {
  const { getAuthHeader } = useclient()
  const saveFmcToken = async (token: string) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/user/addfcmtoken`,
        {
          data: {
            fcmToken: token,
          },
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('Error saving FCM token', error)
      throw error
    }
  }
  const getNotificationList = async () => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/user/getnotifications`,
        {
          headers: getAuthHeader(),
        },
      )
      //sort the by the time
      const transformedData = data.data.sort((a: any, b: any) => {
        return (
          new Date(b.timestamp._seconds).getTime() -
          new Date(a.timestamp._seconds).getTime()
        )
      })
      return transformedData
    } catch (error) {
      console.error('Error getting notification list', error)
      throw error
    }
  }

  return { getNotificationList, saveFmcToken }
}

export default useNotification
