import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const SourcingResultCvview: React.FC<
  { link: string } & React.HtmlHTMLAttributes<HTMLDivElement>
> = ({ link, ...props }) => {
  return (
    <div className={props.className}>
      <Document
        file={link}
        className="flex flex-col gap-[38.32px] items-start justify-start p-[30px] w-full"
      >
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  )
}

export default SourcingResultCvview
