import { memo } from 'react'
import { InputProps } from 'components/Input'
import { cn } from 'utils'

interface searchProps extends InputProps {
  searchFunction: (value: string) => void
  defaultValue: string | number | readonly string[]
}
const Search: React.FC<searchProps> = ({
  searchFunction,
  defaultValue,
  className,
  wrapClassName,
  placeholder,
  label,
  ...restProps
}) => {
  return (
    <div
      className={cn(
        'w-full h-11 px-2.5 bg-[#FFF] rounded-2xl shadow justify-start items-center gap-2 flex',
        wrapClassName,
      )}
    >
      {!!label && label}
      <div className="w-6 h-6 relative ml-[5px] pb-[23px]">
        <img src="/images/Search.svg" alt="search" />
      </div>
      <input
        placeholder={placeholder || 'Search'}
        className={cn(
          "w-full self-stretch placeholder:text-slate-300 text-lg font-normal font-['Poppins'] capitalize border-none",
          className,
        )}
        onChange={(e) => searchFunction(e.target.value)}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default memo(Search)
