import React from 'react'
import DatePicker from 'react-datepicker'
import { ErrorMessage } from '../ErrorMessage'
import 'react-datepicker/dist/react-datepicker.css'
import { useController } from 'react-hook-form'

export type DatepickerProps = Partial<{
  placeholder: string
  className: string
  value: string
  dateFormat: string
  onChange: Function
  errors: any[]
  [x: string]: any
}>

function Datepicker({
  placeholder = 'Select date',
  className,
  dateFormat = 'dd/MM/yyyy',
  control,
  name,
  ...restProps
}: DatepickerProps) {
  const { field, fieldState } = useController({
    control,
    name,
  })

  const onDateChange = (value: Date | null) => {
    const newValue = value instanceof Date ? value.toISOString() : value
    field.onChange(newValue)
  }

  const now = new Date()
  const endOfDay = new Date(now)
  endOfDay.setHours(23, 59, 59, 999) // Set to the end of the current day
  return (
    <>
      <div className={className}>
        <DatePicker
          dateFormat={dateFormat}
          placeholderText={placeholder}
          selected={field.value ? new Date(field.value) : null}
          onChange={onDateChange}
          minDate={new Date()}
          minTime={restProps.minTime ? now : undefined} // Current time to disable past times
          maxTime={restProps.minTime ? endOfDay : undefined} // End of the current day
          {...restProps}
          className="disabled:cursor-not-allowed disabled:text-gray-400"
        />
      </div>
      {!!fieldState.error && (
        <ErrorMessage
          errors={[fieldState.error.message]}
          className="text-right"
        />
      )}
    </>
  )
}

function DatePickerRange({
  startDate,
  endDate,
  placeholder = 'Select range',
  className,
  dateFormat = 'dd/MM/yyyy',
  onChange,
  errors = [],
  ...restProps
}) {
  const [startDateValue, setStartDate] = React.useState(startDate)
  const [endDateValue, setEndDate] = React.useState(endDate)

  const onDateChange = ([start, end]: (Date | null)[]) => {
    const newStartDate = start instanceof Date ? start.toISOString() : start
    const newEndDate = end instanceof Date ? end.toISOString() : end
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    onChange?.([newStartDate, newEndDate])
  }
  return (
    <>
      <div className={className}>
        <DatePicker
          dateFormat={dateFormat}
          placeholderText={placeholder}
          startDate={startDateValue ? new Date(startDateValue) : null}
          endDate={endDateValue ? new Date(endDateValue) : null}
          onChange={onDateChange}
          selectsRange
          {...restProps}
        />
      </div>
      <ErrorMessage errors={errors} />
    </>
  )
}

Datepicker.Range = DatePickerRange
Datepicker.DateTime = (props) => (
  <Datepicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect {...props} />
)
Datepicker.Time = (props) => (
  <Datepicker
    dateFormat="h:mm aa"
    showTimeSelect
    showTimeSelectOnly
    {...props}
  />
)
export { Datepicker }
