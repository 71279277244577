import usePipeline from 'hooks/usePipeline'
import { createContext, useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
interface hiringProvider {
  currentPipline: string
  setCurrentPipline: (value: string) => void
  openPipline: boolean
  setOpenPipline: (value: boolean) => void
  stage: string[]
  newStage: string[]
  setStage: (value: string[]) => void
  setNewStage: (value: string[]) => void
  stageLoading: boolean
  stagePiplineData: object
  setCountry: (value: string) => void
  country: string
}
export const HiringContext = createContext<hiringProvider>(null)

export const HiringProvider = ({ children }) => {
  const { getPipeline } = usePipeline()
  const queryClient = useQueryClient()
  const [currentPipline, setCurrentPipline] = useState<string>('')
  const [openPipline, setOpenPipline] = useState(true)
  const [stage, setStage] = useState<string[]>(['Disqualified', 'Sourced'])
  const [newStage, setNewStage] = useState<string[]>([])
  const [country, setCountry] = useState<string>(null)
  const {
    data: stagePiplineData,
    isLoading: stageLoading,
    ...stageData
  } = useQuery({
    queryKey: ['stageData', currentPipline],
    queryFn: () => getPipeline(currentPipline),
    // enabled: false,
  })
  useEffect(() => {
    const fetchData = async () => {
      if (!currentPipline) return
      const data: { id: string; stages: string[] } = queryClient.getQueryData([
        'piplineDetail',
        currentPipline,
      ])
      if (!data) {
        const {
          data: { stages },
        } = await stageData.refetch()
        setStage(stages)

        return
      }
      if (data.id === currentPipline) {
        setStage(data.stages)

        return
      }
      const {
        data: { stages },
      } = await stageData.refetch()
      setStage(stages)
    }
    fetchData()
  }, [currentPipline])
  return (
    <HiringContext.Provider
      value={{
        currentPipline,
        setCurrentPipline,
        openPipline,
        setOpenPipline,
        stage,
        setStage,
        newStage,
        setNewStage,
        stageLoading,
        stagePiplineData,
        setCountry,
        country,
      }}
    >
      {children}
    </HiringContext.Provider>
  )
}
const useHiringContext = () => useContext(HiringContext)

export default useHiringContext
