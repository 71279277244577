import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { cn } from 'utils'

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode[]
}
const Dropdown = ({ children, ...rest }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropDownRef = React.useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  //handle click outside of the dropdown
  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (
        dropDownRef?.current &&
        !dropDownRef?.current?.contains(event.target)
      ) {
        // Clicked outside the status dropdown, close it
        setIsOpen(false)
      }
    }
    // Attach the event listener
    document.addEventListener('click', handleOutSideClick)
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleOutSideClick)
    }
  }, [])

  return (
    <div
      className={cn('cursor-pointer relative min-w-[101px]', rest.className)}
      ref={dropDownRef}
    >
      <button onClick={toggleDropdown}>{children[0]}</button>
      {isOpen && (
        <button onClick={() => setIsOpen(!isOpen)}>{children.slice(1)}</button>
      )}
    </div>
  )
}

const DropdownTrigger = ({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return <div {...rest}>{children}</div>
}

const DropdownList = ({ children, ...rest }) => {
  return (
    <div
      className={cn(
        'min-w-[122px] absolute top-[120%] left-[-30%] z-[100] max-h-[237px] rounded-md  flex-col justify-start items-start inline-flex overflow-y-auto shadow-1 ',
        rest.className,
      )}
    >
      {children}
    </div>
  )
}

const Drop = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return <div {...rest}>{children}</div>
}

export { Dropdown, DropdownTrigger, DropdownList, Drop }
