import { formatDistance } from 'date-fns'
import useNotification from 'hooks/useNotification'
import toast from 'react-hot-toast'
import { Rings } from 'react-loader-spinner'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

const Notification = () => {
  const { getNotificationList } = useNotification()
  const notification = useQuery({
    queryKey: ['notification'],
    queryFn: getNotificationList,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  return (
    <div
      style={{
        boxShadow:
          '0px 0px 0px 0px rgba(74, 127, 223, 0.10), 1.026px 1.026px 3.079px 0px rgba(74, 127, 223, 0.10), 8.21px 9.236px 8.21px 0px rgba(74, 127, 223, 0.05)',
      }}
      className="w-[353px] overflow-auto max-h-[304px] rounded-b-md shadow flex-col justify-start items-start inline-flex"
    >
      <div className="self-stretch sticky top-0 px-3.5 py-3 bg-[#FFF]  rounded-t-[10px] border-t border-stone-50 justify-between items-start inline-flex">
        <div className="text-zinc-800 text-sm font-normal font-['Roboto']">
          Notifications
        </div>
        <Link
          className="text-zinc-800 text-xs font-normal font-['Roboto'] underline"
          to="/notification"
        >
          View All
        </Link>
      </div>
      {notification.isLoading && (
        <div className="self-stretch px-3.5 py-3 bg-[#FFF] hover:bg-slate-50 border-t border-stone-50 justify-start items-start gap-2 inline-flex">
          <div className="p-10 m-auto">
            <Rings height="80" width="80" color="#4a7fdf" ariaLabel="loading" />
          </div>
        </div>
      )}
      {notification?.data?.map((item, index) => (
        <div
          className="self-stretch px-3.5 py-3 bg-[#FFF] hover:bg-slate-50 border-t border-stone-50 justify-start items-start gap-2 inline-flex"
          key={index}
        >
          <img
            className="w-[39px] h-[39px] rounded-[32px] border-2"
            src="/images/dummy_user.png"
            alt="circleimage_One"
          />
          <div className="flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-zinc-800 text-sm font-normal font-['Roboto']">
              {item.title}
            </div>
            <div className="text-gray-800 text-xs font-normal font-['Roboto']">
              {item.body}
            </div>
            <div className="text-neutral-500 text-xs font-normal font-['Roboto']">
              {formatDistance(
                new Date(),
                new Date(item.timestamp._seconds * 1000),
              )}{' '}
              ago
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Notification
