import { FormField } from 'hooks/usePostRecurtment'
import * as yup from 'yup'
import useHelpCall from 'hooks/useHelpCall'
type UnknownArrayOrObject = unknown[] | Record<string, unknown>

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  )
}

// Define a function to generate the Yup schema based on the array of inputs
export const createSchema = (inputs: FormField[]) => {
  if (!inputs) return yup.object().shape({})
  const schemaObject = {}

  // Loop through the inputs and create schema for each field
  inputs.forEach((input) => {
    // Set field as required if it's not an image type
    const isFieldRequired = input.field_type !== 'image'
    // Set field type validation based on field type
    let validationType
    switch (input.field_type) {
      case 'text':
      case 'date':
      case 'option':
        validationType = yup.string()
        break
      case 'number':
        validationType = yup.number()
        break
      case 'file':
        validationType = yup
          .mixed()
          .test(
            'minimum',
            'minimum one file is required',
            (value: File) => value && value.length > 0,
          )
          .test('minimum', 'minimum one file is required', (value: File) => {
            if (!value) return false
            return value?.length > 0
          })
        break
      default:
        validationType = yup.string() // Default to string type
        break
    }

    // Apply required validation if necessary
    if (isFieldRequired) {
      schemaObject[input.field_name] = validationType.required(
        `${input.field_label} is required`,
      )
    } else {
      schemaObject[input.field_name] = validationType
    }
  })

  return yup.object().shape(schemaObject)
}

//make the file base64 encoded string
export async function getBase64(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64String = reader.result as string
      const base64WithoutPrefix = base64String.split(',')[1]
      resolve(base64WithoutPrefix)
    }
    reader.onerror = reject
  })
}
const { getCountryState } = useHelpCall()
export const getPostalAddress = async (form, prefix) => {
  const pincode = form.getValues(`${prefix}pincode`)

  // Check if pincode is valid
  if (!pincode || pincode.length < 6) {
    return
  }

  // Retrieve current address values
  const currentValues = form.getValues([
    `${prefix}state`,
    `${prefix}city`,
    `${prefix}country`,
    `${prefix}line1`,
    `${prefix}line2`,
  ])

  // Fetch address details based on pincode
  const address = await getCountryState(pincode)

  if (address) {
    // Mapping of form fields to address properties
    const addressFields = {
      [`${prefix}state`]: address.State,
      [`${prefix}city`]: address.Region,
      [`${prefix}country`]: address.Country,
      [`${prefix}line1`]: address.addressLine1,
      [`${prefix}line2`]: address.addressLine2,
    }

    // Set new values only if the fields are empty
    Object.entries(addressFields).forEach(([key, value]) => {
      if (!currentValues[key]) {
        form.setValue(key, value)
      }
    })
  }
}
