import { default as ModalProvider } from 'react-modal'
import { Button, Img, Input, SelectBox, Text } from 'components'
import useProject from 'hooks/useProject'
import useRequirement from 'hooks/useRequirement'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import useHelpCall from 'hooks/useHelpCall'
const AddRecruiter = (props) => {
  const { requirementId } = useParams()
  const { getUsers } = useHelpCall()
  const { addAndGiveAccessToRecruiter } = useRequirement()
  const queryClient = useQueryClient()
  const recruiterList = useQuery({
    queryKey: ['recruiterList'],
    queryFn: () => getUsers(['RECRUITMENT_COORDINATOR', 'ADMIN', 'MANAGER']),
  })

  const recruiterHandler = useMutation({
    mutationKey: ['recruiterHandler'],
    mutationFn: addAndGiveAccessToRecruiter,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
    onSuccess: () => {
      toast.success('Recruiter Added sucessfully.')
      props.onClose()
      queryClient.invalidateQueries({ queryKey: ['recruitersDetail'] })
    },
  })
  const form = useForm()

  const onSubmit = (data) => {
    toast.promise(
      recruiterHandler.mutateAsync({
        job_id: requirementId,
        access: [data],
      }),
      {
        loading: 'Adding the recruiter',
        error: 'Error adding the recruiter for access',
        success: 'Successfully added the recruiter for access',
      },
    )
  }

  return (
    <ModalProvider
      appElement={document.getElementById('root')}
      className="m-auto "
      overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full"
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
    >
      <form
        className="h-auto overflow-y-auto sm:w-full md:w-full min-w-[600px]"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="bg-white-A700 flex flex-col gap-[20px] h-auto md:h-auto items-start justify-start p-10 md:px-5 rounded-[15px] w-auto md:w-full">
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="text-[15px] text-gray-900 w-auto"
              size="txtPoppinsMedium15"
            >
              Recruiter Email
            </Text>
            {recruiterList.isLoading ? (
              <Input
                name="recruiter_id"
                placeholder="select the Recuriter"
                className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                wrapClassName="bg-white-A700 border border-indigo-400 border-solid px-[11px] py-3.5 rounded-[9px] w-full"
                type="text"
              />
            ) : (
              <SelectBox
                control={form.control}
                name="recruiter_id"
                options={recruiterList.data}
                placeholder="select the Recuriter"
                isSearchable={true}
                className="bg-white-A700 border border-indigo-400 border-solid h-[52px] pl-2.5 pr-[13px] py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                    <Img
                      className="h-5 w-5 rounded-[50%]"
                      src="/images/img_uangledown.svg"
                      alt="u:angle-down"
                    />
                  </div>
                }
              />
            )}
          </div>
          <div className="py-2.5 justify-center items-center gap-5 inline-flex">
            <Button
              className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] py-[7px] rounded-[5px] text-center text-sm text-white-A700"
              type="submit"
            >
              Accept
            </Button>
            <Button
              className="w-auto text-base text-center text-gray-700"
              onClick={props.onClose}
              type="button"
            >
              cancel
            </Button>
          </div>
        </div>
      </form>
    </ModalProvider>
  )
}

export default AddRecruiter
