import { baseURL, apis } from 'service'
import useclient from 'hooks/useClient'
import Cookies from 'universal-cookie'

export interface Candidate {
  _id?: string // candidate id can either comming id or _id
  id?: string // candidate id can either comming id or _id
  addedBy: string
  name: string
  firstname: string
  lastname: string
  middelname: string
  password: string
  age: number
  address: {
    line1: string
    line2: string
    city: string
    state: string
    country: string
    pincode: string
  }
  profilePicUrl: string
  email: string
  mobile_number: string
  gender: string
  Skills: string[]
  status: string
  cv: string
  aadhar_card: {
    aadhar_card: string
    aadhar_card_number: string
  }
  passport: {
    passport: string
    passport_number: string
    passport_type: string
    passport_issue_date: Date
    passport_expiry_date: Date
    passport_issue_country: string
  }
  pancard_number: string
  experience: {
    trade: string
    Gulf_experience: number
    Indian_experience: number
    total_experience: number
  }
  contact_relation: string
  contact_firstname: string
  contact_lastname: string
  contact_email: string
  contact_number: string
  contact_address: {
    line1: string
    line2: string
    city: string
    state: string
    country: string
    pincode: string
  }
  createdAt: Date
  updatedAt: Date
  isDeleted: boolean
  isActive: boolean
  applicationDetails: ApplicationDetail[]
  documents: document[]
  locker: [
    {
      passportNumber: string
      checkin: string
      checkout: string
    },
  ]
}

export interface document {
  document_name: string
  document_url: string
  id: string
}
interface ApplicationDetail {
  _id: string
  candidate_id: string
  job_id: string
  pipeline_id: string
  stage: number
  stageName: string
  outreach_medium: string
  candidate_status: string
  createdAt: Date
  updatedAt: Date
  isDeleted: boolean
  isActive: boolean
  jobDetails: JobDetail
  branchDetails: {
    name: string
  }
  projectDetails: {
    name: string
  }
  companyDetails: {
    name: string
  }
}

interface JobDetail {
  _id: string
  addedBy: string
  order: string
  branch: string
  project: string
  title: string
  category: string
  position: number
  skills: string[]
  division: string
  pipeline_id: string
  address: {
    line1: string
    line2: string
    city: string
    state: string
    country: string
    pincode: string
  }
  recruiters: Recruiter[]
  reports_to: string
  start_date: Date
  end_date: Date
  total_openings: number
  job_type: string
  working_hours: number
  salary: {
    hourly: number
    monthly: number
    annually: number
  }
  overtime: boolean
  about: string
  tags: string[]
  speaking_language: string[]
  writing_langugage: string[]
  ecr_ecnr: boolean
  currency: string
  age: number
  allowances: {
    food: string
    medical: string
    transport: string
    other: string
  }
  accomodation: string
  license: boolean
  religion: string
  status: string
  qualification: string
  license_country: string
  joining_ticket: boolean
  travel_advisory: string
  test: string
  socialmediaData: any[]
  createdAt: Date
  updatedAt: Date
  isDeleted: boolean
  isActive: boolean
}

interface Recruiter {
  recruiter_id: string
  accessLevel: string
  _id: string
}

interface emailProps {
  email: string
  subject: string
  content: string
  name: string
}

interface uploadFileProps {
  candidateId: string
  file: {
    file_name: string
    file: File
  }
}

const useCandidate = () => {
  const { getAuthHeader } = useclient()
  const cookies = new Cookies()

  const createCandidate = async (newCandidate) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/candidate/create`,
        {
          data: newCandidate,
          headers: getAuthHeader(),
        },
      )
      return data
    } catch (error) {
      console.error('error while create the candidate', error)
      throw error
    }
  }

  const searchCandidates = async (searchString) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/candidate/searchCandidate?searchStr=${searchString}&limit=4`,
        {
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        return []
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getCandidateById = async (candidateId: string): Promise<Candidate> => {
    try {
      const {
        data,
      }: {
        data: {
          data: Candidate
          message: string
          status: string
        }
      } = await apis.get(
        `${baseURL}/recruiter/api/v1/candidate/getCandidateMasterview/${candidateId}`,
        {
          headers: getAuthHeader(),
        },
      )

      if (!data.data) {
        throw new Error('Candidate data not found')
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addAdssociateWithJob = async ({
    candidateId,
    projectId,
    jobId,
  }: {
    jobId: string
    candidateId: string
    projectId: string
  }) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/associate`,
        {
          data: {
            candidateId,
            projectId,
            jobId,
          },
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const evaluateCandidate = async (result: object) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/evaluateMasterProfile`,
        {
          data: result,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const blockCandidate = async (candidateId: string) => {
    try {
      await apis.get(
        `${baseURL}/recruiter/api/v1/candidate/blockCandidate/${candidateId}`,
        {
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const sendEmail = async (emailcontent: emailProps) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/sendEmailToCandidate`,
        {
          data: emailcontent,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  interface updateLockerProps {
    candidateId: string
    params: object
  }

  const updateLocker = async ({ candidateId, params }: updateLockerProps) => {
    try {
      const { data } = await apis.put(
        `${baseURL}/recruiter/api/v1/candidate/locker/${candidateId}`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const uploadFile = async ({ candidateId, file }: uploadFileProps) => {
    try {
      const formData = new FormData()
      formData.append(file.file_name, file.file)

      const { data } = await apis.put(
        `${baseURL}/recruiter/api/v1/candidate/update/${candidateId}`,
        {
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + cookies.get('authToken'),
          },
        },
      )
      return data?.message || 'File uploaded successfully'
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  const updateCandidate = async ({
    candidateId,
    params,
  }: {
    candidateId: string
    params: object
  }) => {
    const userType =
      JSON.parse(localStorage.getItem('uid'))?.userType == 'RECEPTIONIST'
        ? 'reception'
        : 'recruiter'
    try {
      await apis.put(
        `${baseURL}/${userType}/api/v1/candidate/update/${candidateId}`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('error while updating the candidate', error)
      throw error
    }
  }

  const partitalCandidateUpdate = async ({ candidateId, params }) => {
    try {
      await apis.put(
        `${baseURL}/recruiter/api/v1/candidate/partial-update/${candidateId}`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
    } catch (error) {
      console.error('error while updating the candidate', error)
      throw error
    }
  }

  const verifyCandidate = async (params) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/candidate/verify`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
      return data?.data
    } catch (error) {
      console.error('error while verifying the candidate', error)
      throw error
    }
  }
  interface FormValues {
    csvFile: FileList | null | any
  }
  const uploadBulkCandiate = async (file: FormValues) => {
    const formData = new FormData()
    formData.append('csvFile', file.csvFile[0])
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/upload`,
        {
          data: formData,
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: 'Bearer ' + cookies.get('authToken'),
          },
        },
      )
      return data
    } catch (error) {
      console.error('error while uploading the candidate', error)
      throw error
    }
  }
  interface BulkAssociateProps {
    projectId: string
    jobId: string
    candidateIds: string[]
  }

  const BulkAssociate = async ({
    projectId,
    jobId,
    candidateIds,
  }: BulkAssociateProps) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/bulkAssociate`,
        {
          data: {
            projectId,
            jobId,
            candidateIds,
          },
          headers: getAuthHeader(),
        },
      )
      return data
    } catch (error) {
      console.error('error while associating the candidate', error)
      throw error
    }
  }

  return {
    searchCandidates,
    getCandidateById,
    evaluateCandidate,
    blockCandidate,
    uploadFile,
    sendEmail,
    addAdssociateWithJob,
    createCandidate,
    verifyCandidate,
    updateCandidate,
    uploadBulkCandiate,
    partitalCandidateUpdate,
    BulkAssociate,
    updateLocker,
  }
}

export default useCandidate
