import React, { ReactNode, useState, useEffect } from 'react'
import { default as ModalProvider } from 'react-modal'
import { Button } from 'components'
import { cn } from 'utils'

interface ModalBaseProps {
  children: ReactNode
  triggerComponent?: ReactNode
  modalClass?: string
  acceptFunctions?: () => void
  defaultOpen?: boolean
  saveTitle?: string
  closeModalAfterAccept?: boolean
  isOpen?: boolean
  setIsOpen?: (open: boolean) => void
}

const ModalBase: React.FC<ModalBaseProps> = ({
  children,
  triggerComponent,
  modalClass,
  acceptFunctions,
  defaultOpen,
  saveTitle,
  closeModalAfterAccept = true,
  isOpen,
  setIsOpen,
}) => {
  const [open, setOpen] = useState<boolean>(!!defaultOpen)

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setOpen(isOpen)
    }
  }, [isOpen])

  const handleModal = () => {
    if (setIsOpen) {
      setIsOpen(!open)
    } else {
      setOpen(!open)
    }
  }

  return (
    <>
      {triggerComponent && (
        <button onClick={handleModal} type="button">
          {triggerComponent}
        </button>
      )}
      <ModalProvider
        appElement={document.getElementById('root')}
        className={cn('w-full m-auto max-w-[97vw] ', modalClass)}
        overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full "
        isOpen={open}
        onRequestClose={handleModal}
      >
        <div className="w-full rounded-lg sm:w-full md:w-full bg-white-A700">
          <div className=" flex flex-col gap-[25px] items-start justify-start p-10 md:px-5 rounded-[15px]  md:w-full w-full max-h-[90vh] overflow-y-auto">
            {children}
          </div>
          {/* Buttons */}
          <div className="inline-flex items-center w-full gap-5 border-t p-[22px_38px]">
            <Button
              className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px]  rounded-[5px] text-center text-sm text-white-A700"
              type="submit"
              onClick={() => {
                if (typeof acceptFunctions === 'function') acceptFunctions()
                if (closeModalAfterAccept) handleModal()
              }}
            >
              {saveTitle || 'Accept'}
            </Button>
            <Button
              className="w-auto text-base text-center text-gray-700"
              onClick={handleModal}
              type="button"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalProvider>
    </>
  )
}

export default ModalBase
