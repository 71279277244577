import { apis, baseURL } from 'service'
import useclient from './useClient'
import { Candidate } from './useCandidate'

export interface searchForSourcing {
  job_id: string
  project_id: string
  inputSkills: string[]
  minExperience: number
  gulfExperience: number
  inputLanguages: string[]
  mainTrade: string
  subTrade: string
  //   limit: number
  //   offset: number
}

interface OutreachStatus {
  outreach_id: string
  candidate_id: string
  status: string
}

export interface SourceHistory {
  project_id: string
  job_title: string
  sourced_by: string
  cv_sourced_length: number
  outreached: number
  _id: string
  parameter: {
    inputSkills: string[]
    minExperience: number
    gulfExperience: number
    inputLanguages: string[]
    mainTrade: string
    subTrade: string
    project_id: string
    job_id: string
  }
}

export interface CandidateByStatus {
  stats: {
    project_id: string
    job_name: string
    cv_sourced: number
    reached: number
    followup: number
  }
  candidates: Candidate[]
  details: {
    candidateid: string
    name: string
    createdAt: string
  }[]
}

const useSourcing = () => {
  const { getAuthHeader } = useclient()
  const searchCandidatesForSourcing = async (
    sourcingQuery: searchForSourcing,
  ) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/sourcingInJob`,
        {
          data: sourcingQuery,
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        return null
      }
      return data.data
    } catch (error) {
      console.error(
        'error in searching for the candidate in the sourcing',
        error,
      )

      throw error
    }
  }

  const getJobs = async () => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/job/getRecruiterJobs`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('error getting the jobs', error)
      throw error
    }
  }

  const getSourcingHistory = async (params) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/jobs/sourcinghistory`,
        {
          headers: getAuthHeader(),
          data: params,
        },
      )
      const response = data?.data == null ? [] : data.data
      return response
    } catch (error) {
      console.error('error getting the sorcing history')
      throw error
    }
  }

  const updateSourcingStatus = async (outreach: OutreachStatus) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/jobs/updateoutreachstatus`, {
        data: outreach,
        headers: getAuthHeader(),
      })
    } catch (error) {
      console.error('error updating the outreach status', error)
      throw error
    }
  }

  const getCandidateByStatus = async (
    params: object,
  ): Promise<CandidateByStatus> => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/jobs/candidatesbystatus`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('error getting the candidate by status', error)
      throw error
    }
  }
  const sendInteriewRequest = async (params: object) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/oauth/scheduleinterview`, {
        data: params,
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error('error in sendInteriewRequest ', error)
      throw error
    }
  }
  return {
    searchCandidatesForSourcing,
    getSourcingHistory,
    updateSourcingStatus,
    getCandidateByStatus,
    getJobs,
    sendInteriewRequest,
  }
}

export default useSourcing
