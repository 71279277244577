import usePostRecurtment, {
  applicantRefrence,
  applicationDetail,
} from 'hooks/usePostRecurtment'
import { createContext, useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

interface PostRecurtmentContext {
  currentApplicant: applicantRefrence
  setCurrentApplicant: (value: object) => void
  currentApplicantData: applicationDetail
  currentApplicantLoading: boolean
}

export const PostRecurtmentContext = createContext<PostRecurtmentContext>(null)

export const PostRecurtmentProvider = ({ children }) => {
  const { getApplicantData } = usePostRecurtment()
  const [currentApplicant, setCurrentApplicant] =
    useState<applicantRefrence | null>(null)
  const [currentApplicantData, setCurrentApplicantData] = useState(null)

  //getting the currentApplicant data
  const { isLoading: currentApplicantLoading } = useQuery<applicationDetail>({
    queryKey: ['currentApplicantData', currentApplicant],
    queryFn: () => getApplicantData(currentApplicant),
    enabled: !!currentApplicant,
    onSuccess(data) {
      setCurrentApplicantData(data)
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
      window.location.href = '/postrecrutment'
    },
  })

  return (
    <PostRecurtmentContext.Provider
      value={{
        currentApplicant,
        setCurrentApplicant,
        currentApplicantData,
        currentApplicantLoading,
      }}
    >
      {children}
    </PostRecurtmentContext.Provider>
  )
}

const usePostRecurtmentContext = () => useContext(PostRecurtmentContext)

export default usePostRecurtmentContext
