import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Button, Img, Text } from 'components'
import Notification from 'modals/Notification'
import ProfilePopUp from 'modals/Profile'
import HeaderSearch from 'modals/HeaderSearch'
import CreateCandidate from 'modals/createCandidate'
import { VscAccount } from 'react-icons/vsc'
import { FaRegAddressCard } from 'react-icons/fa6'
import { LuPhoneCall } from 'react-icons/lu'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import Logout from 'modals/Logout'
import { cn } from 'utils'
import { RiDashboardLine } from 'react-icons/ri'
type HeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{}>

const Header: React.FC<HeaderProps> = (props) => {
  const uid = JSON.parse(localStorage.getItem('uid'))
  const navigate = useNavigate()
  const location = useLocation() // Get the current location
  const [showNotification, setShowNotification] = React.useState(false)
  const notificationRef = React.useRef<HTMLDivElement>(null)
  const [showLogoutConfirmation, setShowLogoutConfirmation] =
    React.useState(false)
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotification(!showNotification)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showNotification])

  const isPageActive = (pathname: string) => {
    return location.pathname === pathname
  }

  return (
    <header
      style={{
        boxShadow:
          '0px 0px 0px 0px rgba(74, 127, 223, 0.10), 1px 1px 3px 0px rgba(74, 127, 223, 0.10), 4px 4px 6px 0px rgba(74, 127, 223, 0.09), 8px 9px 8px 0px rgba(74, 127, 223, 0.05), 15px 17px 9px 0px rgba(74, 127, 223, 0.01), 23px 26px 10px 0px rgba(74, 127, 223, 0.00)',
      }}
      className={props.className}
    >
      {showLogoutConfirmation && (
        <Logout setShowLogoutConfirmation={setShowLogoutConfirmation} />
      )}
      {uid?.userType !== 'RECEPTIONIST' ? (
        <>
          <div className="flex flex-row items-center justify-between w-auto sm:w-full">
            <Img
              className="h-11 md:h-auto w-11"
              src="/images/img_logo.png"
              alt="circleimage_One"
            />
            <div className="flex sm:ml-[20px] sm:overflow-auto flex-row gap-5 items-center justify-between sm:pl-5 pl-[30px] w-auto">
              <Button
                className={`${
                  isPageActive('/master') ? 'bg-blue-50' : 'bg-white-A700'
                } hover:bg-blue-50 common-pointer cursor-pointer flex items-center justify-center min-w-[102px] px-2.5 py-[5px] rounded-[10px]`}
                onClick={() => navigate('/master')}
                leftIcon={
                  <Img
                    className="h-6 mr-2.5"
                    src="/images/img_trash.svg"
                    alt="trash"
                  />
                }
              >
                <div className="text-sm text-left font-poppins text-gray-900_01">
                  Master
                </div>
              </Button>
              <Button
                className={`${
                  isPageActive('/sourcing') ? 'bg-blue-50' : 'bg-white-A700'
                } hover:bg-blue-50 common-pointer cursor-pointer flex items-center justify-center min-w-[103px] px-2.5 py-[5px] rounded-[10px]`}
                onClick={() => navigate('/sourcing')}
                leftIcon={
                  <Img
                    className="h-6 mr-2.5"
                    src="/images/img_file.svg"
                    alt="file"
                  />
                }
              >
                <div className="text-sm text-left font-poppins text-gray-900_01">
                  Source
                </div>
              </Button>
              <Button
                className="hover:bg-blue-50 bg-white-A700 cursor-pointer flex items-center justify-center min-w-[100px] px-2.5 py-[5px] rounded-[10px]"
                leftIcon={
                  <Img
                    className="h-6 mr-2.5"
                    src="/images/img_prefix.svg"
                    alt="prefix"
                  />
                }
              >
                <div className="text-sm text-left font-poppins text-gray-900_01">
                  Report
                </div>
              </Button>
              <Button
                className={`${
                  isPageActive('/outreach') ? 'bg-blue-50' : 'bg-white-A700'
                } hover:bg-blue-50 common-pointer cursor-pointer flex items-center justify-center min-w-[103px] px-2.5 py-[5px] rounded-[10px]`}
                onClick={() => navigate('/outreach')}
                leftIcon={
                  <Img
                    className="h-6 mr-2.5"
                    src="/images/img_call.svg"
                    alt="call"
                  />
                }
              >
                <div className="text-sm text-left font-poppins text-gray-900_01">
                  Out-Reach
                </div>
              </Button>
              <HeaderSearch />
            </div>
          </div>
          <div className="flex flex-row gap-[15px] items-center justify-start w-auto">
            <div className="relative transition ease-in-out delay-150 cursor-pointer">
              <button onClick={() => setShowNotification(!showNotification)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 19V20H3V19L5 17V11C5 7.9 7.03 5.17 10 4.29V4C10 3.46957 10.2107 2.96086 10.5858 2.58579C10.9609 2.21071 11.4696 2 12 2C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V4.29C16.97 5.17 19 7.9 19 11V17L21 19ZM14 21C14 21.5304 13.7893 22.0391 13.4142 22.4142C13.0391 22.7893 12.5304 23 12 23C11.4696 23 10.9609 22.7893 10.5858 22.4142C10.2107 22.0391 10 21.5304 10 21"
                    fill="#4A7FDF"
                  />
                </svg>
              </button>

              {showNotification && (
                <div>
                  <div
                    style={{
                      backgroundColor: 'rgba(64, 64, 64, .2)',
                    }}
                    className="fixed top-[57px] left-0 z-[10] h-full w-[100vw] flex justify-end items-center"
                  ></div>
                  <div
                    className="absolute z-[100] top-[40.5px] right-[-160px]"
                    ref={notificationRef}
                  >
                    <Notification />
                  </div>
                </div>
              )}
            </div>

            <ProfilePopUp />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-end w-full gap-4 py-2 sm:w-full ">
          <Img
            className="h-11 md:h-auto w-11"
            src="/images/img_logo.png"
            alt="circleimage_One"
          />
          <div className="flex-1">
            <Text className="font-semibold capitalize text-gray-900_01">
              Welcome, {uid?.name}!
            </Text>
          </div>
          <CreateCandidate>
            <Button
              leftIcon={<VscAccount />}
              className="flex items-center gap-3"
            >
              Create Candidate
            </Button>
          </CreateCandidate>
          <Link to="/dashboard">
            <Button
              leftIcon={<RiDashboardLine />}
              className={cn(
                'flex items-center gap-3 p-2  rounded-md bg-blue-50',
                isPageActive('/dashboard')
                  ? 'bg-blue-50 text-blue-500'
                  : 'bg-white-A700',
              )}
            >
              Dashboard
            </Button>
          </Link>
          <Link to="/calllog">
            <Button
              leftIcon={<FaRegAddressCard />}
              className={cn(
                'flex items-center gap-3 p-2  rounded-md bg-blue-50',
                isPageActive('/calllog')
                  ? 'bg-blue-50 text-blue-500'
                  : 'bg-white-A700',
              )}
            >
              Caller Logs
            </Button>
          </Link>
          <Link to="/visitorLog">
            <Button
              leftIcon={<LuPhoneCall />}
              className={cn(
                'flex items-center gap-3 p-2  rounded-md bg-blue-50',
                isPageActive('/visitorLog')
                  ? 'bg-blue-50 text-blue-500'
                  : 'bg-white-A700',
              )}
            >
              Visitor Logs
            </Button>
          </Link>
          <Button
            leftIcon={<RiLogoutBoxRLine />}
            className={cn('flex items-center gap-3')}
            onClick={() => setShowLogoutConfirmation(true)}
          >
            Logout
          </Button>
        </div>
      )}
    </header>
  )
}

export default Header
