import { createContext, useContext, useState } from 'react'

interface orderContext {
  currentOrder: string
  setCurrentOrder: (value: string) => void
  orderModal: boolean
  setOrderModal: (value: boolean) => void
  handleOrderModal: (value: string | null) => void
}

export const OrderContext = createContext<orderContext>(null)

export const OrderProvider = ({ children }) => {
  const [currentOrder, setCurrentOrder] = useState<string | null>(null)
  const [orderModal, setOrderModal] = useState<boolean>(false)

  const handleOrderModal = (orderId: string | null) => {
    setOrderModal(!orderModal)
    setCurrentOrder(orderId)
  }

  return (
    <OrderContext.Provider
      value={{
        currentOrder,
        setCurrentOrder,
        orderModal,
        setOrderModal,
        handleOrderModal,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

const useOrderContext = () => useContext(OrderContext)

export default useOrderContext
