import { useMemo, useState } from 'react'
import type {
  ColumnDef,
  ExpandedState,
  TableOptions,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table'
import { cn } from 'utils'
import { Skeleton } from 'components/skelton'

type ReactTableProps<D> = {
  data: D[]
  columns: ColumnDef<D>[]
  rowClass?: string
  headerClass?: string
  enableSorting?: boolean
  headerProps?: {}
  bodyProps?: {}
  rowDataProps?: {}
  rowClick?: (rowData: D) => void
  isLoading?: boolean
}

const ReactTable = <D extends unknown>({
  columns,
  data = [],
  rowClass = '',
  headerClass = '',
  enableSorting = false,
  headerProps = {},
  bodyProps = {},
  rowDataProps = {},
  rowClick,
  isLoading,
  ...restConfig
}: ReactTableProps<D>) => {
  const modifiedData = useMemo(() => data, [data])
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const tableConfig = {
    columns,
    data,
    state: {
      modifiedData,
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),

    ...restConfig,
  } as TableOptions<D>

  const table = useReactTable(tableConfig)
  // Render the UI for your table
  return (
    <table>
      <thead {...headerProps}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className={headerClass} key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                className={
                  header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                }
                onClick={header.column.getToggleSortingHandler()}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...bodyProps}>
        {isLoading ? (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={index} className="p-2">
                {columns.map((column, idx) => (
                  <td key={idx}>
                    <Skeleton className="w-full h-4 my-2 " />
                  </td>
                ))}
              </tr>
            ))}
          </>
        ) : (
          table.getRowModel().rows.map((row, index) => (
            <tr
              className={cn(rowClass, {
                'cursor-pointer': typeof rowClick == 'function',
                'bg-[#F9FBFF]': index % 2 == 0,
                'bg-white-A700': index % 2 != 0,
              })}
              key={row.id}
            >
              {row.getVisibleCells().map((cell, idx) => (
                <td
                  key={cell.id}
                  onClick={(event) => {
                    event.stopPropagation()
                    if (
                      typeof rowClick == 'function' &&
                      idx !== row.getVisibleCells().length - 1
                    ) {
                      rowClick(row.original)
                    }
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

export { ReactTable }
