import React from 'react'

import { Text } from 'components'
import { cn } from 'utils'

type StageDivProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'stage'
> &
  Partial<{ value: string | number; stage: string }>

const StageDiv: React.FC<StageDivProps> = (props) => {
  return (
    <div className={cn(props.className)} {...props}>
      <Text className="text-xl text-center" size="txtPoppinsRegular20">
        {props?.value}
      </Text>
      <Text className="text-[11px] text-center " size="txtPoppinsRegular11">
        {props?.stage}
      </Text>
    </div>
  )
}

export default StageDiv
