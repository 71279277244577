import { Button, Img } from 'components'
import Search from 'components/Search'
import useHelpCall from 'hooks/useHelpCall'
import { useState } from 'react'
import { default as ModalProvider } from 'react-modal'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { cn } from 'utils'
import { Rings } from 'react-loader-spinner'

const HeaderSearch = () => {
  const { headerSearch } = useHelpCall()
  const [open, setOpen] = useState<boolean>(false)
  const handleModal = () => setOpen(!open)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearchQuery = useDebouncedCallback((value: string) => {
    setSearchQuery(value)
  }, 700)

  const { isLoading, data } = useQuery({
    queryKey: ['headerSearch', searchQuery],
    queryFn: () => headerSearch(searchQuery),
    enabled: searchQuery.length > 0,
  })
  return (
    <>
      <Button
        className={cn(
          'hover:bg-blue-50 bg-white-A700 cursor-pointer flex items-center justify-center min-w-[104px] px-2.5 py-[5px] rounded-[10px]',
          open && 'bg-blue-50',
        )}
        leftIcon={
          <Img
            className="h-6 mr-2.5"
            src="/images/img_search.svg"
            alt="search"
          />
        }
        onClick={handleModal}
      >
        <div className="text-sm text-left font-poppins text-gray-900_01">
          Search
        </div>
      </Button>
      <ModalProvider
        appElement={document.getElementById('root')}
        className={cn('w-full m-auto max-w-[900px]')}
        overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full "
        isOpen={open}
        onRequestClose={handleModal}
      >
        <div className="max-h-[97vh]  sm:w-full md:w-full w-full overflow-y-scroll">
          <div className="bg-white-A700 flex flex-col gap-[25px] items-start justify-start  md:px-5 rounded-[15px]  md:w-full w-full p-5 ">
            <Search
              wrapClassName="!shadow-none"
              searchFunction={handleSearchQuery}
              defaultValue={searchQuery}
            />
            {isLoading ? (
              <div className="p-10 m-auto">
                <Rings
                  height="80"
                  width="80"
                  color="#4a7fdf"
                  ariaLabel="loading"
                />
              </div>
            ) : (
              <div className="grid w-full gap-2 px-10">
                <div className="my-3">
                  <span className="text-gray-500">All Projects</span>
                  <div className="max-h-[100px] overflow-y-scroll w-full">
                    {data?.projectResults.map((project) => (
                      <Link
                        to={`/projectdetails/${project.id}`}
                        key={project.id}
                        onClick={handleModal}
                      >
                        <div className="w-full my-3 capitalize">
                          {' '}
                          <p>
                            {project.project_id}, {project.name}{' '}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="my-3">
                  <span className="text-gray-500">All Requirement</span>
                  <div className="max-h-[100px] overflow-y-scroll w-full">
                    {data?.jobResults.map((job) => (
                      <Link
                        to={`/requirementOverview/${job.id}`}
                        key={job.id}
                        onClick={handleModal}
                      >
                        <div className="w-full my-3 capitalize">
                          <p>
                            {' '}
                            {job.job_id}, {job.title}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="my-3">
                  <span className="text-gray-500">All Clients</span>
                  <div className="max-h-[100px] overflow-y-scroll w-full">
                    {data?.clientResults.map((client) => (
                      <Link
                        to={`/clientdetails?clientId=${client.id}`}
                        key={client.id}
                        onClick={handleModal}
                      >
                        <div className="w-full my-3 capitalize">
                          <p>
                            {client.client_id}, {client.client_name}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                {/* <div className="my-3">
                  <span className="text-gray-500">All Candidate</span>
                  <div className="max-h-[100px] overflow-y-scroll w-full">
                    {data?.candidateResults.map((client) => (
                      <Link
                        to={`/masterview/${client.id}`}
                        key={client.id}
                        onClick={handleModal}
                      >
                        <div className="w-full my-3 capitalize">{}</div>
                      </Link>
                    ))}
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </ModalProvider>
    </>
  )
}

export default HeaderSearch
