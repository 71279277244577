import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from 'pages/Home'
import NotFound from 'pages/NotFound'
import Layout from 'Common/layout'
import Spinner from 'Common/spinner'
import PostRecrutmentOverview from 'pages/postRecrutmentOverview'
import VisitorLog from 'pages/VisitorLog'
import CallerLog from 'pages/CallerLog'
import Notification from 'pages/Notification'
import OutReach from 'components/Outreach'
import ForgotOtp from 'components/ForgotOtp'
import SetNewPassword from 'components/SetPassword'
// import Dashboard from 'components/CXO/Dashboard'
const MasterView = React.lazy(() => import('pages/MasterView'))
const Master = React.lazy(() => import('pages/Master'))
const VisaPostProcess = React.lazy(() => import('pages/VisaPostProcess'))
const CreatePostRecrutmentOne = React.lazy(
  () => import('pages/CreatePostRecrutmentOne'),
)
const CreatePostRecrutment = React.lazy(
  () => import('pages/CreatePostRecrutment'),
)
const PostRecrutment = React.lazy(() => import('pages/PostRecrutment'))
const OutReachPage = React.lazy(() => import('pages/OutReach'))
const SourcingResult = React.lazy(() => import('pages/SourcingResult'))
const Sourcing = React.lazy(() => import('pages/Sourcing'))
const SelectionProcess = React.lazy(() => import('pages/SelectionProcess'))
const RequirementOverview = React.lazy(
  () => import('pages/RequirementOverview'),
)
const AddRequirementsStepThree = React.lazy(
  () => import('pages/AddRequirementsStepThree'),
)
const AddRequirementsStepTwo = React.lazy(
  () => import('pages/AddRequirementsStepTwo'),
)
const AddRequirementsStepOne = React.lazy(
  () => import('pages/AddRequirementsStepOne'),
)
const Requirements = React.lazy(() => import('pages/Requirements'))
const ProjectDetails = React.lazy(() => import('pages/ProjectDetails'))
const Projects = React.lazy(() => import('pages/Projects'))
const Calander = React.lazy(() => import('pages/Calander'))
const Dashboard = React.lazy(() => import('pages/Dashboard'))
const Employee = React.lazy(() => import('pages/Employee'))
const SplashLoginScreen = React.lazy(() => import('pages/SplashLoginScreen'))
const Createemployee = React.lazy(() => import('pages/CreateEmployee'))
const Setting = React.lazy(() => import('pages/Setting'))
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'))
const Profile = React.lazy(() => import('pages/Profile'))
const Clients = React.lazy(() => import('pages/Clients'))
const ClientDetails = React.lazy(() => import('pages/ClientsDetails'))
const CompanyDetails = React.lazy(() => import('pages/Companydetails'))
const HiringPipeline = React.lazy(() => import('pages/HiringPipeline'))
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<SplashLoginScreen />} />
        <Route path="/otp" element={<ForgotOtp />} />
        <Route path="/forgetPassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<SetNewPassword />} />
        <Route element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/calander" element={<Calander />} />
          <Route path="/projects" element={<Projects />} />
          <Route
            path="/projectdetails/:projectId"
            element={<ProjectDetails />}
          />
          <Route path="/requirements" element={<Requirements />} />
          <Route
            path="/addrequirementsstepone/:projectId"
            element={<AddRequirementsStepOne />}
          />
          <Route
            path="/addrequirementssteptwo/:orderId"
            element={<AddRequirementsStepTwo />}
          />
          <Route
            path="/addrequirementsstepthree/:requirementId"
            element={<AddRequirementsStepThree />}
          />
          <Route
            path="/requirementoverview/:requirementId"
            element={<RequirementOverview />}
          />
          <Route
            path="/selectionprocess/:requirementId"
            element={<SelectionProcess />}
          />
          <Route path="/sourcing" element={<Sourcing />} />
          <Route path="/sourcingresult" element={<SourcingResult />} />
          <Route path="/outreach/:outreachId" element={<OutReachPage />} />
          <Route path="/postrecrutment" element={<PostRecrutment />} />
          <Route
            path="/postrecrutmentoverview/:postRecurtmentId"
            element={<PostRecrutmentOverview />}
          />
          <Route
            path="/createpostrecrutment"
            element={<CreatePostRecrutment />}
          />

          {/* //TDOD: remove this  createpostrecrutmentone already exist in createpostrecrutmentone */}
          <Route
            path="/createpostrecrutmentone"
            element={<CreatePostRecrutmentOne />}
          />
          <Route path="/visapostprocess" element={<VisaPostProcess />} />
          <Route path="/master" element={<Master />} />
          <Route path="/masterview/:candidateId" element={<MasterView />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/createemployee" element={<Createemployee />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clientdetails" element={<ClientDetails />} />
          <Route path="/companydetails" element={<CompanyDetails />} />
          <Route path="/hiringpipeline" element={<HiringPipeline />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/outreach" element={<OutReach />} />

          {/* reception route */}
          <Route path="/visitorLog" element={<VisitorLog />} />
          <Route path="/calllog" element={<CallerLog />} />
        </Route>
      </Routes>
    </React.Suspense>
  )
}
export default ProjectRoutes
