import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logout from '../../modals/Logout'
import {
  Drop,
  Dropdown,
  DropdownList,
  DropdownTrigger,
} from 'components/Dropdown'
import { Img, Text } from 'components'
type ProfileProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const Profile: React.FC<ProfileProps> = () => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] =
    React.useState(false)
  const uid = JSON.parse(localStorage.getItem('uid'))
  const navigate = useNavigate()
  return (
    <>
      {showLogoutConfirmation && (
        <Logout setShowLogoutConfirmation={setShowLogoutConfirmation} />
      )}
      <Dropdown>
        <DropdownTrigger className="flex items-center gap-2">
          <Img
            className="h-11 md:h-auto rounded-[50%] w-11 cursor-pointer"
            src={uid?.profilePicUrl || '/images/dummy_user.png'}
            alt="circleimage_One"
          />
          <Text
            className="w-auto text-lg text-gray-900 uppercase"
            size="txtPoppinsMedium18Gray900"
          >
            {uid?.name || 'Null'}
          </Text>
        </DropdownTrigger>
        <DropdownList>
          <Drop
            className="self-stretch px-3.5 py-3 bg-[#FFF] rounded-tl rounded-tr border-t border-stone-50 justify-start items-center gap-2 inline-flex"
            onClick={() => navigate('/profile')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M7.40233 1.23438C3.99415 1.23438 1.23145 3.99708 1.23145 7.40526C1.23145 10.8134 3.99415 13.5761 7.40233 13.5761C10.8105 13.5761 13.5732 10.8134 13.5732 7.40526C13.5732 3.99708 10.8105 1.23438 7.40233 1.23438Z"
                stroke="#282A2C"
                strokeWidth="0.822785"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.63281 11.3217C2.63281 11.3217 4.0083 9.56547 7.40229 9.56547C10.7963 9.56547 12.1724 11.3217 12.1724 11.3217M7.40229 7.40566C7.89328 7.40566 8.36416 7.21061 8.71134 6.86343C9.05852 6.51625 9.25356 6.04538 9.25356 5.55439C9.25356 5.0634 9.05852 4.59253 8.71134 4.24535C8.36416 3.89817 7.89328 3.70312 7.40229 3.70312C6.91131 3.70312 6.44043 3.89817 6.09325 4.24535C5.74607 4.59253 5.55103 5.0634 5.55103 5.55439C5.55103 6.04538 5.74607 6.51625 6.09325 6.86343C6.44043 7.21061 6.91131 7.40566 7.40229 7.40566Z"
                stroke="#282A2C"
                strokeWidth="0.822785"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Profile
          </Drop>
          <Drop
            className="self-stretch px-3.5 py-3 bg-[#FFF] rounded-tl rounded-tr border-t border-stone-50 justify-start items-center gap-2 inline-flex"
            onClick={() => setShowLogoutConfirmation(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M8.9326 9.48675C8.82575 9.36047 8.77233 9.2173 8.77233 9.05722C8.77233 8.89714 8.82575 8.76349 8.9326 8.65625L10.0108 7.57806H5.82916C5.66403 7.57806 5.52551 7.52211 5.41361 7.41021C5.30172 7.29831 5.24596 7.15999 5.24635 6.99525C5.24635 6.83013 5.3023 6.69161 5.4142 6.57971C5.5261 6.46781 5.66442 6.41206 5.82916 6.41245H10.0108L8.9326 5.33426C8.81604 5.2177 8.75776 5.07918 8.75776 4.91872C8.75776 4.75825 8.81604 4.61993 8.9326 4.50376C9.03945 4.3872 9.1731 4.32892 9.33357 4.32892C9.49404 4.32892 9.6275 4.38234 9.73396 4.48919L11.8321 6.58729C11.8903 6.64557 11.9317 6.70871 11.9562 6.7767C11.9807 6.8447 11.9927 6.91755 11.9923 6.99525C11.9923 7.07296 11.9803 7.14581 11.9562 7.21381C11.9321 7.2818 11.8907 7.34494 11.8321 7.40322L9.73396 9.50132C9.60768 9.62759 9.46917 9.68354 9.31842 9.66917C9.16766 9.65479 9.03906 9.59399 8.9326 9.48675ZM2.91513 12.2405C2.59458 12.2405 2.32008 12.1263 2.09162 11.8978C1.86316 11.6694 1.74912 11.3951 1.74951 11.0749V2.91561C1.74951 2.59507 1.86374 2.32057 2.0922 2.09211C2.32066 1.86365 2.59497 1.74961 2.91513 1.75H6.41196C6.57709 1.75 6.7156 1.80595 6.8275 1.91785C6.9394 2.02975 6.99516 2.16807 6.99477 2.33281C6.99477 2.49794 6.93882 2.63645 6.82692 2.74835C6.71502 2.86025 6.5767 2.916 6.41196 2.91561H2.91513V11.0749H6.41196C6.57709 11.0749 6.7156 11.1308 6.8275 11.2427C6.9394 11.3546 6.99516 11.493 6.99477 11.6577C6.99477 11.8228 6.93882 11.9613 6.82692 12.0732C6.71502 12.1851 6.5767 12.2409 6.41196 12.2405H2.91513Z"
                fill="#282A2C"
              />
            </svg>
            Logout
          </Drop>
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default Profile
