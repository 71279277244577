import { ReactNode, useEffect } from 'react'
import ModalBase from 'modals/BaseModal'
import { Text, Input, FileUpload, SelectBox, Img, Datepicker } from 'components'
import { Controller, useForm } from 'react-hook-form'
import { cn } from 'utils/index'
import { useMutation, useQuery } from 'react-query'
import usePostRecurtment, {
  FormField,
  stageFieldProp,
} from 'hooks/usePostRecurtment'
import { yupResolver } from '@hookform/resolvers/yup'
import usePostRecurtmentContext from 'context/PostRecurtmentContext'
import { createSchema } from 'helper'
import toast from 'react-hot-toast'
import Spinner from 'Common/spinner'
import { Link } from 'react-router-dom'
interface documnetmodal extends stageFieldProp {
  children: ReactNode
  data: object
}

const DocumentModal = ({ country, stage, children, data }: documnetmodal) => {
  const { getfieldWithStageName, uploadApplicantFile } = usePostRecurtment()
  const { currentApplicant } = usePostRecurtmentContext()

  const formField = useQuery<FormField[] | []>({
    queryKey: ['stageFormField', country, stage],
    queryFn: () =>
      getfieldWithStageName({
        country: country.toLowerCase(),
        stage: stage.toLowerCase(),
      }),
    staleTime: Infinity,
  })

  const uploadHandler = useMutation({
    mutationKey: ['applicantDocumentUploadHandler'],
    mutationFn: uploadApplicantFile,
    onError: (error: any) => {
      toast.error(
        error.response.data.message || 'error uplaoding the document try again',
      )
    },
  })

  const DocumnetFileInput = ({
    name,
    title,
    fileType = 'application/pdf',
    className = '',
    label = 'upload the document',
    wrapperClass = '',
    ...rest
  }) => {
    return (
      <>
        <div className={cn('w-full mt-10', wrapperClass)}>
          <Text className="mb-3 text-gray-700 capitalize">{title}</Text>
          <Controller
            name={name as never}
            control={form.control}
            render={({ field, fieldState }) => (
              <FileUpload
                preview
                className={cn(
                  'flex flex-1 sm:flex-1 h-full px-[17px] rounded-[10px] sm:w-full dash-border py-5 w-full max-w-[600px]',
                  className,
                )}
                name={name}
                error={fieldState?.error}
                Thumbnail={FileUpload.PreviewItem}
                onUpload={(files) => field.onChange(files)}
                allowMultiple={true}
                acceptedFileTypes={fileType}
                Placeholder={() => (
                  <div className="flex items-center justify-between w-full text-sm text-gray-">
                    <Text className="capitalize">{label}</Text>
                    <span className="border-2 rounded-xl min-w-[100px] capitalize flex items-center justify-center py-2">
                      browse
                    </span>
                  </div>
                )}
                {...rest}
              />
            )}
          />
        </div>
        {form.getValues(name) && (
          <Link
            to={form.getValues(name)}
            download={`${name}-${currentApplicant.applicationid}.pdf`}
            target="_blank"
            className="text-blue-500"
          >
            View Document
          </Link>
        )}
      </>
    )
  }
  const form = useForm({
    defaultValues: data as any,
    resolver: yupResolver(createSchema(formField?.data)),
  })
  const onsubmit = (data) => {
    console.log(data)
    toast.promise(
      uploadHandler.mutateAsync({
        applicationId: currentApplicant.applicationid,
        stage: stage,
        status: 'InProgress',
        data: data,
      }),
      {
        loading: 'Uploading the document',
        success: 'Document uploaded successfully',
        error: undefined,
      },
    )
  }

  useEffect(() => {
    form.reset(data)
  }, [data])


  if (uploadHandler.isLoading) {
    return <Spinner />
  }
  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[1000px]"
      acceptFunctions={form.handleSubmit(onsubmit)}
    >
      <div className="w-full">
        <Text
          className="w-auto text-xl text-gray-900 capitalize"
          size="txtPoppinsMedium20Gray900"
        >
          Upload the Documents
        </Text>
        <Text size="txtPoppinsRegular11Black900" className="text-gray-500">
          Fill the received date and upload the necessary details.
        </Text>
      </div>
      {!formField.isLoading && (
        <div className="w-full">
          {formField.data ? (
            formField.data.map((input: FormField) => {
              switch (input.field_type) {
                case 'file':
                  return (
                    <DocumnetFileInput
                      key={input.field_name}
                      name={input.field_name}
                      title={input.field_label}
                      className="max-h-[100px] max-w-[500px]"
                      label={input.field_label}
                      type={`${input.field_type}`}
                      wrapperClass="flex lg:items-center justify-between w-full gap-3 md:flex-col mt-4"
                    />
                  )
                case 'text':
                  return (
                    <div
                      className="flex justify-between w-full gap-3 my-4 lg:items-center md:flex-col"
                      key={input.field_name}
                    >
                      <Text
                        className="w-auto text-gray-700 capitalize text-md whitespace-nowrap"
                        size="txtPoppinsRegular11Black900"
                      >
                        {input.field_label}
                      </Text>
                      <Input
                        name={input.field_name}
                        className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full"
                        wrapClassName="border w-full grid place-items-center px-[10px] bg-white-A700 border-gray-400 border-solid rounded-[7px] min-h-[40px] max-w-[500px] "
                        control={form.control}
                        placeholder={
                          input.field_placeholder || 'Enter the field'
                        }
                      />
                    </div>
                  )
                case 'option':
                  return (
                    <div
                      className="flex justify-between w-full gap-3 my-4 lg:items-center md:flex-col"
                      key={input.field_name}
                    >
                      <Text
                        className="w-auto text-gray-700 capitalize text-md whitespace-nowrap"
                        size="txtPoppinsRegular11Black900"
                      >
                        {input.field_label}
                      </Text>
                      <SelectBox
                        className="bg-white-A700 border  border-solid h-[40px] px-2.5 rounded-[9px] text-[15px] tracking-[0.50px] w-full max-w-[500px]   py-[6px]"
                        placeholderClassName="text-black-900_60"
                        indicator={
                          <Img
                            className="h-4 w-4 p-[3px] rounded-[50%]"
                            src="/images/img_uangledown.svg"
                            alt="u:angle-down"
                          />
                        }
                        name={input.field_name}
                        options={input.field_options.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        control={form.control}
                      />
                    </div>
                  )
                case 'date':
                  return (
                    <div
                      className="flex justify-between w-full gap-3 my-4 lg:items-center md:flex-col"
                      key={input.field_name}
                    >
                      <Text
                        className="w-auto text-gray-700 capitalize text-md whitespace-nowrap"
                        size="txtPoppinsRegular11Black900"
                      >
                        {input.field_label}
                      </Text>
                      <Datepicker
                        className="px-[15px] py-[10px] border  rounded-[10px]  border-[#EAEAEA] bg-[#F9FBFF] max-w-[500px] w-full"
                        placeholder="DD/MM/YYYY"
                        control={form.control}
                        name={input.field_name}
                      ></Datepicker>
                    </div>
                  )
              }
            })
          ) : (
            <div>
              <Text
                className="w-auto text-xl text-gray-900 capitalize"
                size="txtPoppinsMedium20Gray900"
              >
                no inputs available for this stage. Please create the required
                inputs.
              </Text>
            </div>
          )}
        </div>
      )}
    </ModalBase>
  )
}

export default DocumentModal
