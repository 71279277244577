import { Button, Img, SelectBox, Text } from 'components'
import DownloadDocument from 'components/DownloadDocument'
import NotFound from 'components/NotFound'
import { progressOption } from 'constant/OptionInstance'
import usePostRecurtmentContext from 'context/PostRecurtmentContext'
import { document } from 'hooks/useCandidate'
import usePostRecurtment, { stages } from 'hooks/usePostRecurtment'
import FlyoutCheckList from 'modals/FlyoutCheckList'
import DocumentModal from 'modals/candidate/Document'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { FaPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { cn } from 'utils'

interface CandidateStageDetail {
  stages: stages[]
  conuntry: string
  loading: boolean
  documents: document[]
  flyoutCheckList?: object
  cv: string
}

const CandidateStageDetail = ({
  stages,
  documents,
  cv,
  loading,
  conuntry,
  flyoutCheckList,
}: CandidateStageDetail) => {
  const { updatePostRecurtmentStatus } = usePostRecurtment()
  const { currentApplicant } = usePostRecurtmentContext()
  const [stageStatus, setStageStatus] = useState({})
  const queryClient = useQueryClient()
  const form = useForm()
  const statusHandler = useMutation({
    mutationKey: ['updatePostRecurtmentStatus'],
    mutationFn: updatePostRecurtmentStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(['currentApplicantData', currentApplicant])
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ||
          'error uplaoding the document  status try again',
      )
    },
  })

  const onsubmit = (data) => {
    // TDOD: need to check the dirty values
    // const dirtyValue = dirtyValues(form.formState.dirtyFields, data)
    const newValue = Object.entries(data).map(([key, value]) => ({
      stage: key,
      status: value,
    }))
    toast.promise(
      statusHandler.mutateAsync({
        statusarray: newValue,
        applicationId: currentApplicant.applicationid,
      }),
      {
        loading: 'updating the status...',
        success: 'status updated successfully',
        error: 'error updating the status',
      },
    )
  }

  const areDocumentsAvailable = documents && documents.length > 0
  const isCvAvailable = cv && cv.trim() !== ''

  if (!stages) {
    return <div>Please Select Any One Candidate To See Stages..</div>
  }

  if (loading) {
    return <div>loading the stages...</div>
  }

  return (
    <div className="flex justify-between gap-3 my-2 md:flex-col">
      <div className="flex-1 border rounded-lg">
        {stages.map((item, index) => {
          const status = item.status.toLowerCase()
          return (
            <div
              className="flex items-center justify-between px-3 my-5"
              key={item.stage}
            >
              <Text className="capitalize">
                {index + 1}. {item.stage}
              </Text>
              <div className="flex items-center gap-3">
                <SelectBox
                  className={cn(
                    'bg-white-A700 border border-indigo-400 border-solid h-[40px] px-2.5 rounded-[9px] text-[15px] tracking-[0.50px] min-w-[250px] max-w-[300px] py-[6px]',
                    (status === 'inprogress' ||
                      stageStatus[item.stage]?.toLowerCase() ===
                        'inprogress') &&
                      'text-orange-400',
                    (status === 'completed' ||
                      stageStatus[item.stage]?.toLowerCase() === 'completed') &&
                      'text-green-400',
                    (status === 'notstarted' ||
                      stageStatus[item.stage]?.toLowerCase() ===
                        'notstarted') &&
                      'text-red-400',
                    (status === 'incompleted' ||
                      stageStatus[item.stage]?.toLowerCase() ===
                        'incompleted') &&
                      'text-red-400',
                  )}
                  placeholderClassName="text-black-900_60"
                  indicator={
                    <Img
                      className="h-4 w-4 p-[3px] rounded-[50%]"
                      src="/images/img_uangledown.svg"
                      alt="u:angle-down"
                    />
                  }
                  name={item.stage}
                  options={progressOption}
                  control={form.control}
                  defaultValue={item.status}
                  onChange={(value) =>
                    setStageStatus({
                      ...stageStatus,
                      [item.stage]: value.value,
                    })
                  }
                />

                <DocumentModal
                  country={conuntry}
                  stage={item.stage}
                  data={item?.data}
                >
                  <FaPlus className="cursor-pointer" />
                </DocumentModal>
              </div>
            </div>
          )
        })}
        <div className="flex items-center justify-between px-3 my-5">
          <Text className="capitalize">
            {stages.length + 1}. Flyout CheckList
          </Text>
          <FlyoutCheckList stages={stages} defaultValues={flyoutCheckList}>
            <div className="flex items-center gap-3">
              <Text className="capitalize">see checklist</Text>{' '}
              <FaPlus className="cursor-pointer" />
            </div>
          </FlyoutCheckList>
        </div>
        <Button
          className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] py-[7px] rounded-[5px] text-center text-sm text-white-A700 m-3 float-right "
          onClick={form.handleSubmit(onsubmit)}
        >
          Submit
        </Button>
      </div>
      <div className="min-w-[300px] border rounded-lg p-3">
        {areDocumentsAvailable || isCvAvailable ? (
          <>
            <p className="font-medium font-poppins">
              Download Candidate Documents
            </p>
            {documents.map((doc) => (
              <DownloadDocument
                key={doc.id}
                title={doc.document_name}
                url={doc.document_url}
                name={doc.id}
              />
            ))}
            {isCvAvailable && <DownloadDocument title="CV" url={cv} />}
          </>
        ) : (
          <NotFound subtitle="No Documents Available To Download" title="" />
        )}
      </div>
    </div>
  )
}

export default CandidateStageDetail
