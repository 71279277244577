import { useState } from 'react'

export interface QueryOptions {
  collation?: string
  sort?: object
  populate?: string
  projection?: string
  lean?: boolean
  leanWithId?: boolean
  offset?: number
  page?: number
  limit?: number
  pageCount?: number
  hasNextPage?: boolean
  hasPrevPage?: boolean
  pagination?: boolean
  useEstimatedCount?: boolean
  useCustomCountFn?: boolean
  forceCountFn?: boolean
  read?: Record<string, any>
  options?: Record<string, any>
}

export interface QueryData {
  query?: {
    [key: string]: string | number | [] | undefined | boolean
  }
  options?: QueryOptions
  isCountOnly?: boolean
}

const useFilter = () => {
  const [filterOption, setFilterOption] = useState<QueryData>({
    query: {
      search: true,
    },
    options: {
      pageCount: 0,
      hasNextPage: false,
      hasPrevPage: false,
      page: 1,
      offset: 0,
      sort: { updatedAt: -1 },
    },
  })

  const setPaginatorData = ({
    pageCount,
    hasNextPage,
    hasPrevPage,
    offset,
  }) => {
    setFilterOption((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        pageCount,
        hasNextPage,
        hasPrevPage,
        offset,
      },
    }))
  }

  //if we have the next page then updated the page and apply for the next 10 data by updating the offset with (offset + 10)
  const refetchPaginationData = (next: number) => {
    setFilterOption((prev) => {
      const updatedOptions = {
        ...prev.options,
        page: next,
        offset: (next - 1) * (prev.options.limit || 10),
      }
      return {
        ...prev,
        options: updatedOptions,
      }
    })
  }

  const setQuery = (query: object) => {
    setFilterOption((prev) => {
      return {
        ...prev,
        query: {
          ...prev.query,
          ...query,
        },
      }
    })
  }

  return {
    filterOption,
    setFilterOption,
    setPaginatorData,
    refetchPaginationData,
    setQuery,
  }
}

export default useFilter
