import React, { HTMLAttributes } from 'react'
import { Button, Text } from 'components'
import { cn } from 'utils'

interface notFount extends HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle: string
  buttontitle?: string
}

const NotFound = (props: notFount) => {
  return (
    <div
      {...props}
      className={cn(
        'grid place-items-center rounded-xl max-w-[300px] text-center p-3 gap-3',
        props.className,
      )}
    >
      <img src="/images/notFound.svg" alt="result not found" />
      <Text
        className="text-[20px] text-gray-900 w-auto"
        size="txtPoppinsMedium15"
      >
        {props.title}
      </Text>
      <Text
        className="text-[15px] text-gray-900 w-auto"
        size="txtPoppinsMedium15"
      >
        {props.subtitle}
      </Text>
      {props.buttontitle && (
        <Button
          className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] rounded-[5px] text-center text-sm text-white-A700"
          type="button"
          {...props.onClick}
        >
          {props.buttontitle}
        </Button>
      )}
    </div>
  )
}

export default NotFound
