import { formatDistance } from 'date-fns'
import useNotification from 'hooks/useNotification'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

const Notification = () => {
  const { getNotificationList } = useNotification()
  const notification = useQuery({
    queryKey: ['notification'],
    queryFn: getNotificationList,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })
  return (
    <div className="w-full min-h-[572.57px] px-5 pt-5 pb-10 flex-col justify-start items-start gap-[15px] inline-flex">
      <div className="inline-flex items-center self-stretch justify-between">
        <div className="text-neutral-900 text-xl font-medium font-['Poppins'] uppercase">
          Notification
        </div>
      </div>
      <div className="overflow-auto w-full h-auto bg-[#FFF] rounded-[17.34px] shadow flex-col justify-start items-start gap-[11.56px] flex p-[20px]">
        {notification?.data?.map((item, index) => (
          <div
            className="self-stretch px-3.5 py-3 bg-[#FFF] hover:bg-slate-50 border-t border-stone-50 justify-start items-start gap-2 inline-flex"
            key={index}
          >
            <img
              className="w-[39px] h-[39px] rounded-[32px] border-2"
              src="/images/dummy_user.png"
              alt="circleimage_One"
            />
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-zinc-800 text-sm font-normal font-['Roboto']">
                {item.title}
              </div>
              <div className="text-gray-800 text-xs font-normal font-['Roboto']">
                {item.body}
              </div>
              <div className="text-neutral-500 text-xs font-normal font-['Roboto']">
                {formatDistance(
                  new Date(),
                  new Date(item.timestamp._seconds * 1000),
                )}{' '}
                ago
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Notification
