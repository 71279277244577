import { Text, Button, Img } from 'components'
import { cn } from 'utils'
interface Props {
  data: string
  textClass?: string
  buttonClass?: string
  imgClass?: string
  text?: string
  button?: string
  img?: string
}
const Header = ({ data, ...props }: Props) => {
  return (
    <div className="w-full flex justify-start  pt-[15px]">
      <div className="w-[170px] h-11 px-6 py-3 justify-start items-center gap-3 inline-flex">
        <div className="flex items-center justify-start gap-1">
          <Text
            className={cn(
              'text-[#272833] font-[Roboto] text-xs  leading-[18px] uppercase whitespace-nowrap font-semibold',
              props.textClass,
            )}
          >
            {data}
          </Text>
          <Button className="cursor-pointer">
            <Img src="/images/cross.svg" alt="sorting cross" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
