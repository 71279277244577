import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Header from 'components/Header'
import useAuth from 'hooks/useAuth'
import Spinner from 'Common/spinner'
import toast from 'react-hot-toast'
import Sidebar from 'components/Sidebar1'

const Layout: React.FC = ({ children }) => {
  const { isloggedIn, loading } = useAuth()
  const uid = JSON.parse(localStorage.getItem('uid'))

  // const { collapseSidebar, collapsed } = useProSidebar()
  // md: flex - col
  if (loading) {
    return <Spinner />
  }

  if (!isloggedIn) {
    toast.error('you must be logged in.')
    return <Navigate to="/" />
  }
  return (
    <div className="flex flex-col items-start justify-start w-auto bg-gray-100 font-poppins sm:w-full md:w-full">
      <Header className="bg-white-A700 flex md:flex-col md:gap-2.5 items-center justify-between md:px-5 px-[35px] py-2.5 w-full" />
      <div className="flex  flex-row gap-2.5 items-start justify-start w-full">
        {uid?.userType !== 'RECEPTIONIST' && (
          <Sidebar className="!sticky bg-blue-A200  flex h-screen md:hidden justify-start overflow-auto md:px-5 top-[0]" />
        )}

        <div
          className={'w-full max-w-cal(100%-250px) flex flex-col gap-2.5 p-5'}
        >
          <Outlet />
        </div>
      </div>
    </div>
    // <>
    //   <Outlet />
    // </>
  )
}

export default Layout
