import { Text } from 'components/Text'
import { cn } from 'utils'

interface CellProps {
  data: string | unknown
  TextClass?: string
}

const Cell = ({ data, TextClass, ...props }: CellProps) => {
  return (
    <div
      className="max-w-[300px] min-h-[72px] px-6 py-4  flex-col justify-center items-start inline-flex"
      {...props}
    >
      <Text
        className={cn(
          'text-sm leading-tight text-blue_gray-900_01 font-roboto',
          TextClass,
        )}
      >
        {data || ''}
      </Text>
    </div>
  )
}

export default Cell
