import React from 'react'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useController } from 'react-hook-form'

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'size' | 'prefix' | 'type' | 'onChange'
> &
  Partial<{
    wrapClassName: string
    className: string
    name: string
    placeholder: string
    type: string
    errors: string[]
    label: string
    prefix: React.ReactNode
    suffix: React.ReactNode
    control: any
    desc?: string
  }>

const Input: React.FC<InputProps> = ({
  wrapClassName = '',
  className = '',
  name = '',
  placeholder = '',
  type = 'text',
  children,
  label = '',
  prefix,
  suffix,
  control,
  defaultValue,
  desc,
  ...restProps
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: defaultValue || '',
  })
  return (
    <div className="flex flex-col items-end w-full">
      <div className={`${wrapClassName}`}>
        {!!label && label}
        {!!prefix && prefix}
        <input
          className={`${className} bg-transparent border-0 !text-gray-900 placeholder:text-gray-400 `}
          type={type}
          placeholder={placeholder}
          {...field}
          {...restProps}
        />
        {!!suffix && suffix}
      </div>
      {!!desc && (
        <div className="mt-1 text-[13px] text-gray-500 text-left w-full">
          {desc}
        </div>
      )}
      {!!fieldState.error && (
        <ErrorMessage
          errors={[fieldState.error.message]}
          className="text-right"
        />
      )}
    </div>
  )
}

export { Input }
