import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Img, List, Switch, Text } from 'components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useRequirement from 'hooks/useRequirement'
import Spinner from 'Common/spinner'
import { cn, dateFormat } from 'utils'
import { FormProvider, useForm } from 'react-hook-form'
import RequirementOverviewListitem from 'components/RequirementOverviewListitem'
import PlusButton from 'components/PlusButton'
import AddRecruiter from 'modals/AddRecruiter'
import toast from 'react-hot-toast'
import useSelectionPropcess from 'hooks/useSelectionPropcess'
import StageDiv from 'components/StageDiv'

const RequirementOverviewPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { getRequirement, getRecruiter, addAndGiveAccessToRecruiter } =
    useRequirement()
  const { getPipeLineStats } = useSelectionPropcess()
  const navigate = useNavigate()
  const { requirementId } = useParams()
  const queryClient = useQueryClient()

  const { data: stats, ...pipelineStats } = useQuery({
    queryKey: ['pipelineStats', requirementId],
    queryFn: () => getPipeLineStats(requirementId),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
      navigate('/dashboard')
    },
  })

  const { data: requiredData, ...requirementOverview } = useQuery({
    queryKey: ['requirementOverview', requirementId],
    queryFn: () => getRequirement(requirementId),
    enabled: !!requirementId,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
      navigate('/dashboard')
    },
  })

  const recruitersDetail = useQuery({
    queryKey: ['recruitersDetail', requirementId],
    queryFn: () => getRecruiter(requirementId),
    enabled: !!requirementId,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
      navigate('/dashboard')
    },
  })
  const form = useForm({ mode: 'onChange' })
  const recruiterHandler = useMutation({
    mutationKey: ['recruiterHandler'],
    mutationFn: addAndGiveAccessToRecruiter,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['recruitersDetail'] })
    },
  })
  const Onsubmit = (data: { stages: string[] }) => {
    const newArray = Object.entries(data)
      .filter(([recruiter_id, stages]) => stages && stages.length > 0)
      .map(([recruiter_id, stages]) => ({
        recruiter_id,
        stages,
      }))

    toast.promise(
      recruiterHandler.mutateAsync({
        job_id: requirementId,
        access: newArray,
      }),
      {
        loading: 'updating the access',
        error: 'Error while updating the access',
        success: 'Successfully updated the access',
      },
    )
  }

  const handleModal = () => setModalOpen(!modalOpen)

  if (requirementOverview.isLoading) {
    return <Spinner />
  }
  return (
    <>
      <div className="flex flex-col gap-[15px] items-start justify-start  pb-10 pt-5 px-5 w-full">
        <header className="bg-white-A700 flex flex-col items-center justify-center pt-3.5 md:px-5 rounded-[15px] shadow-bs w-full">
          <div className="flex flex-1 sm:flex-col flex-row gap-[27px] items-center justify-between px-2.5 w-full">
            <div className="flex sm:flex-1 flex-col items-start justify-start pl-[15px] pr-2.5 py-2.5 w-[370px] sm:w-full">
              <Text
                className="w-auto text-xl text-gray-900"
                size="txtPoppinsRegular20"
              >
                Requirement : {requiredData?.title}
              </Text>
            </div>
            <div className="flex flex-col items-start justify-start p-2.5 w-auto">
              <Button className="bg-light_green-A700 flex items-center justify-center border border-light_green-A700 border-solid cursor-pointer font-medium h-[35px] leading-[normal] min-w-[107px] py-[9px] rounded-lg text-[11px] text-center text-white-A700 tracking-[0.30px] uppercase">
                {requiredData.job_type}
              </Button>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start flex-1 w-full border-t border-gray-200 border-solid bg-white-A700 md:flex-col md:gap-5">
            <div className="bg-white-A700 border-gray-200 border-r border-solid flex md:flex-1 flex-col items-start justify-end p-[17px] w-[12%] md:w-full">
              <Img
                className="h-11 md:ml-[0] ml-[19px]"
                src="/images/img_menu_blue_a200.svg"
                alt="menu"
              />
            </div>
            <List
              className="flex items-center justify-center w-full common-pointer md:flex-col min-h-[75px]"
              orientation="horizontal"
            >
              {stats?.stages.map((stage) => (
                <Link
                  to={`/selectionprocess/${requirementId}?d=${stage.stageName}&c=${stage.stageNo}`}
                  key={stage.stageName}
                  className="flex items-center justify-center w-full h-full md:flex-wrap"
                >
                  <div key={`StageDiv${stage.stageName}`} className="w-full">
                    <StageDiv
                      className="bg-white-A700 border-gray-200 border-r border-solid flex flex-col items-center justify-end p-[3px] w-full h-full"
                      value={stage.candidateCount}
                      stage={stage.stageName}
                    />
                  </div>
                </Link>
              ))}
            </List>
          </div>
        </header>
        <div className="bg-white-A700 flex md:flex-col flex-row md:gap-5 items-start justify-start  mx-auto md:px-5 rounded-[15px] shadow-bs w-full">
          <div className="border-gray-200 border-r border-solid flex flex-1 flex-col gap-2.5 h-full items-start justify-start p-[25px] sm:px-5 w-full">
            <div className="flex flex-row items-center justify-between w-full sm:gap-10">
              <Text
                className="w-auto text-xl text-gray-900"
                size="txtPoppinsMedium20Gray900"
              >
                OVERVIEW
              </Text>
              <Button className="flex items-center justify-center bg-orange-A200 border border-orange-A200 border-solid cursor-pointer font-medium h-[35px] leading-[normal] min-w-[95px] py-[9px] rounded-lg text-[11px] text-center text-white-A700 tracking-[0.30px] uppercase">
                {requiredData?.status}
              </Button>
            </div>
            <div className="bg-white-A700 flex flex-col gap-2.5 items-start justify-start pb-5 pr-[15px] pt-2.5 w-full">
              <List
                className="flex flex-col gap-[9px] items-center w-full"
                orientation="vertical"
              >
                <DataView title="Job Title" data={requiredData?.title} />
                <DataView
                  title="Date Created"
                  data={dateFormat(requiredData?.start_date)}
                />
                <DataView
                  title="Date Created"
                  data={dateFormat(requiredData?.end_date)}
                />
              </List>
              <List
                className="flex flex-col gap-2.5 items-center w-full"
                orientation="vertical"
              >
                <DataView title="Position" data={requiredData?.position} />
                <DataView title="Grade" data="Yet to write" />
                <DataView title="age" data={requiredData?.age} />
                <DataView title="Department" data={requiredData?.division} />
                <DataView title="Reports to" data={requiredData?.reports_to} />
                <DataView title=" Direct Reports" data="Yet to write" />
                <ArrayView title=" Skills" data={requiredData?.skills} />
                <ArrayView
                  title=" Speaking Language"
                  data={requiredData?.speaking_language}
                />
                <ArrayView
                  title=" Writing Language"
                  data={requiredData.writing_langugage}
                />
                <DataView title="Ecr Ecnr" data={requiredData.ecr_ecnr} />
              </List>
            </div>
            <Text
              className="capitalize text-[15px] font-[500] text-black-900 w-[247px]"
              size="txtPoppinsMedium15Black900"
            >
              Display Job Opening on Website
            </Text>
            <div className="flex flex-col gap-2.5 items-start justify-start pb-5 w-auto sm:w-full">
              <div className="flex flex-row items-center justify-between w-full sm:w-full">
                <Text
                  className="capitalize text-[15px] text-gray-900 w-auto"
                  size="txtPoppinsRegular15"
                >
                  Display Job Opening on Website
                </Text>
                <Switch
                  onColor="#2761ff19"
                  offColor="#ebebeb"
                  onHandleColor="#0042fc"
                  offHandleColor="#545454"
                  value={requiredData?.publish?.company_details}
                  className=""
                  disabled={true}
                />
              </div>
              <div className="flex flex-row gap-[37px] items-center justify-between w-full sm:w-full">
                <Text
                  className="capitalize text-[15px] text-gray-900 w-auto"
                  size="txtPoppinsRegular15"
                >
                  Display Client/Company Details on Website
                </Text>
                <Switch
                  onColor="#2761ff19"
                  offColor="#ebebeb"
                  onHandleColor="#0042fc"
                  offHandleColor="#545454"
                  value={requiredData?.publish?.job_opening}
                  className=""
                  disabled={true}
                />
              </div>
              <div className="flex flex-row items-center justify-between w-full sm:w-full">
                <Text
                  className="capitalize text-[15px] text-gray-900 w-auto"
                  size="txtPoppinsRegular15"
                >
                  Show Hiring Process on Website
                </Text>
                <Switch
                  onColor="#2761ff19"
                  offColor="#ebebeb"
                  onHandleColor="#0042fc"
                  offHandleColor="#545454"
                  value={requiredData?.publish?.Hiring_Process}
                  className=""
                  disabled={true}
                />
              </div>
            </div>
            <div className="flex justify-between w-full">
              <Text
                className="capitalize text-[15px] text-black-900 w-auto"
                size="txtPoppinsMedium15Black900"
              >
                Team and Access
              </Text>
              <PlusButton
                title="Add Recruiter"
                className="flex items-center cursor-pointer"
                onClick={handleModal}
              />
            </div>
            <FormProvider {...form}>
              <form
                className="flex flex-col items-start w-full gap-5 py-5"
                onSubmit={form.handleSubmit(Onsubmit)}
              >
                {recruitersDetail.isLoading ? (
                  <div>access loading....</div>
                ) : (
                  <>
                    <RequirementOverviewListitem
                      requireterData={recruitersDetail.data}
                      stages={requiredData.pipeline.stages}
                    />
                    <Button
                      className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer  min-w-[103px] p-[7px] rounded-[5px] text-center text-sm text-white-A700 mt-6"
                      type="submit"
                      // disabled={!form.formState.isDirty || !form.formState.isValid || recruiterHandler.isLoading}
                    >
                      Update Access
                    </Button>
                  </>
                )}
              </form>
            </FormProvider>
          </div>
          <div className="flex flex-1 flex-col items-start justify-start p-[25px] sm:px-5 w-full h-full">
            <textarea
              className="w-full h-screen overflow-y-auto text-sm font-medium text-gray-900 border-none font-poppins"
              value={requiredData.about}
              disabled
            />
          </div>
        </div>
      </div>
      <AddRecruiter isOpen={modalOpen} onClose={handleModal} />
    </>
  )
}

export const DataView = ({
  title,
  data,
  textClass,
  dataClass,
  wrapperClass,
}: {
  dataClass?: string
  textClass?: string
  wrapperClass?: string
  title: string
  data: string
}) => {
  return (
    <div
      className={cn(
        'flex flex-1 sm:flex-col flex-row gap-[18px] justify-start w-full items-center',
        wrapperClass,
      )}
    >
      <Text
        className={cn('w-auto text-sm text-gray-900 ')}
        size="txtPoppinsRegular14Gray900"
      >
        <span className={textClass}>{title} :</span>
      </Text>
      <div className="flex flex-col items-start justify-start flex-1 w-full">
        <Text
          className={cn('text-sm text-gray-900_01 ')}
          size="txtPoppinsRegular14Gray90001"
        >
          <span className={dataClass}>{String(data) || ''}</span>
        </Text>
      </div>
    </div>
  )
}

export const ArrayView = ({
  title,
  data,
  textClass,
  dataClass,
  wrapperClass,
}: {
  dataClass?: string
  textClass?: string
  wrapperClass?: string
  title: string
  data: string[]
}) => {
  return (
    <div
      className={cn(
        'flex flex-1 sm:flex-col flex-row gap-[18px] justify-start w-full items-center',
        wrapperClass,
      )}
    >
      <Text
        className="w-auto text-sm text-gray-900"
        size="txtPoppinsRegular14Gray900"
      >
        <span className={textClass}>{title}</span> :
      </Text>
      <div className="flex flex-col items-start justify-start flex-1 w-full">
        <Text
          className="text-sm text-gray-900_01"
          size="txtPoppinsRegular14Gray90001"
        >
          {data.map((view) => (
            <span key={view} className={dataClass}>
              {String(view)} ,
            </span>
          ))}
        </Text>
      </div>
    </div>
  )
}

export default RequirementOverviewPage
