import React, { ReactNode, useState } from 'react'
import { cn } from 'utils'

interface TabProps {
  children: ReactNode
  className?: string
}

interface TabListProps {
  children?: ReactNode
  activeTab?: number
  handleTabClick?: (value: number) => void
  className?: string
  childClassName?: string
  wrapClassName?: string
}

interface TabTriggerProps {
  value?: number
  activeTab?: number | string
  handleTabClick?: (value: number) => void
  children?: ReactNode
  activeClass?: string
  className?: string
}

interface TabContentProps {
  children: ReactNode
  value: number
}
// Create a Tab component to manage the state
export const Tab: React.FC<TabProps> = ({ children, className }) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (value: number) => {
    setActiveTab(value)
  }

  return (
    <div className={className || ''}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === TabList) {
            return React.cloneElement(child as React.ReactElement<any>, {
              activeTab,
              handleTabClick,
            })
          }
          if (child.type === TabContent && child.props.value === activeTab) {
            return child
          }
        }
        return null
      })}
    </div>
  )
}

// Create TabList component to contain TabTrigger components
export const TabList: React.FC<TabListProps> = ({
  children,
  activeTab,
  handleTabClick,
  childClassName,
  wrapClassName,
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === TabTrigger) {
      return React.cloneElement(child as React.ReactElement<any>, {
        activeTab,
        handleTabClick,
      })
    }
    return child
  })

  return (
    <div className={wrapClassName}>
      <ul className={cn('flex', childClassName)}>{childrenWithProps}</ul>
    </div>
  )
}

// Create TabTrigger component
export const TabTrigger: React.FC<TabTriggerProps> = ({
  value,
  activeTab,
  handleTabClick,
  children,
  activeClass,
  className,
}) => {
  return (
    <li
      className={cn('', className, value === activeTab ? activeClass : '')}
      onClick={() => handleTabClick(value)}
    >
      {children}
    </li>
  )
}

// Create TabContent component
export const TabContent: React.FC<TabContentProps> = ({
  children,
  ...rest
}) => {
  return <div {...rest}>{children}</div>
}
