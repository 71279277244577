import React from 'react'
import { Link } from 'react-router-dom'
const Home = () => {
  return (
    <div className="levitation-navigation">
      <h1>Index</h1>

      <ul>
        <li>
          <Link to="/splashloginscreen">SplashLoginScreen</Link>
        </li>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/calander">Calander</Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <Link to="/projectdetails">ProjectDetails</Link>
        </li>
        <li>
          <Link to="/requirements">Requirements</Link>
        </li>
        <li>
          <Link to="/addrequirementsstepone">AddRequirementsStepOne</Link>
        </li>
        <li>
          <Link to="/addrequirementssteptwo">AddRequirementsStepTwo</Link>
        </li>
        <li>
          <Link to="/addrequirementsstepthree">AddRequirementsStepThree</Link>
        </li>
        <li>
          <Link to="/requirementoverview">RequirementOverview</Link>
        </li>
        <li>
          <Link to="/selectionprocess">SelectionProcess</Link>
        </li>
        <li>
          <Link to="/sourcing">Sourcing</Link>
        </li>
        <li>
          <Link to="/sourcingresult">SourcingResult</Link>
        </li>
        <li>
          <Link to="/outreach">OutReach</Link>
        </li>
        <li>
          <Link to="/postrecrutment">PostRecrutment</Link>
        </li>
        <li>
          <Link to="/createpostrecrutment">CreatePostRecrutment</Link>
        </li>
        <li>
          <Link to="/createpostrecrutmentone">CreatePostRecrutmentOne</Link>
        </li>
        <li>
          <Link to="/visapostprocess">VisaPostProcess</Link>
        </li>
        <li>
          <Link to="/master">Master</Link>
        </li>
        <li>
          <Link to="/masterview">MasterView</Link>
        </li>
      </ul>
    </div>
  )
}
export default Home
