export const userRoleOptions = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Employee', value: 'Employee' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Recruitment Coordinator', value: 'Recruitment_Coordinator' },
  { label: 'Receptionist', value: 'Receptionist' },
]

export const TrueOrFalse = [
  { label: 'yes', value: 'true' },
  { label: 'no', value: 'false' },
]

export const YesOrNo = [
  { label: 'yes', value: 'Yes' },
  { label: 'no', value: 'No' },
]
export const religionOptions = [
  { label: 'Christianity', value: 'christianity' },
  { label: 'Islam', value: 'islam' },
  { label: 'Hinduism', value: 'hinduism' },
  { label: 'Buddhism', value: 'buddhism' },
  { label: 'Judaism', value: 'judaism' },
  { label: 'Sikhism', value: 'sikhism' },
  { label: 'Other', value: 'other' },
  // Add more religion options as needed
]

export const genderOptions = [
  { label: 'male', value: 'male' },
  {
    label: 'female',
    value: 'female',
  },
  {
    label: 'other',
    value: 'other',
  },
]

export const qualificationOptions = [
  { label: 'High School', value: 'high school' },
  { label: 'Middel School', value: 'middel school' },
  { label: 'SSC', value: 'ssc' },

  // Add more qualification options as needed
]
export const languageOptionsList = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'French', value: 'french' },
  { label: 'German', value: 'german' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Kannada', value: 'kannada' },
  { label: 'Malayalam', value: 'malayalam' },
  { label: 'Odia', value: 'odia' },
  { label: 'Punjabi', value: 'punjabi' },
  { label: 'Assamese', value: 'assamese' },
  { label: 'Maithili', value: 'maithili' },
  { label: 'Bhili', value: 'bhili' },
  { label: 'Santali', value: 'santali' },
  { label: 'Kashmiri', value: 'kashmiri' },
  { label: 'Nepali', value: 'nepali' },
  { label: 'Konkani', value: 'konkani' },
  { label: 'Sindhi', value: 'sindhi' },
  { label: 'Dogri', value: 'dogri' },
  { label: 'Meitei', value: 'meitei' },
  { label: 'Bodo', value: 'bodo' },
  // Add more language options as needed
]

export const jobTypeOptions = [
  { label: 'Full-time', value: 'full_time' },
  { label: 'Part-time', value: 'part_time' },
  // Add more job type options as needed
]

export const evaluateOption = [
  {
    label: 'Very Bad',
    value: '1',
  },
  {
    label: 'Bad',
    value: '2',
  },
  {
    label: 'Good',
    value: '3',
  },
  {
    label: 'Very Good',
    value: '4',
  },
  {
    label: 'Excellent',
    value: '5',
  },
]

export const skillsOptions = [
  { label: 'Construction Worker', value: 'Construction Worker' },
  { label: 'Electrician', value: 'Electrician' },
  { label: 'Plumber', value: 'Plumber' },
  { label: 'Welder', value: 'Welder' },
  { label: 'Carpenter', value: 'Carpenter' },
  { label: 'Mechanic', value: 'Mechanic' },
  { label: 'Truck Driver', value: 'Truck Driver' },
  { label: 'HVAC Technician', value: 'HVAC Technician' },
  { label: 'Factory Worker', value: 'Factory Worker' },
  { label: 'Landscaper', value: 'Landscaper' },
  { label: 'Maintenance Worker', value: 'Maintenance Worker' },
  { label: 'Painter', value: 'Painter' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Roofer', value: 'Roofer' },
  { label: 'Heavy Equipment Operator', value: 'Heavy Equipment Operator' },
]

export const currencyOptions = [
  { label: 'United States Dollar (USD)', value: 'USD' },
  { label: 'Euro (EUR)', value: 'EUR' },
  { label: 'Japanese Yen (JPY)', value: 'JPY' },
  { label: 'British Pound Sterling (GBP)', value: 'GBP' },
  { label: 'Australian Dollar (AUD)', value: 'AUD' },
  { label: 'Canadian Dollar (CAD)', value: 'CAD' },
  { label: 'Swiss Franc (CHF)', value: 'CHF' },
  { label: 'Chinese Yuan (CNY)', value: 'CNY' },
  { label: 'Swedish Krona (SEK)', value: 'SEK' },
  { label: 'New Zealand Dollar (NZD)', value: 'NZD' },
  { label: 'Mexican Peso (MXN)', value: 'MXN' },
  { label: 'Singapore Dollar (SGD)', value: 'SGD' },
  { label: 'Hong Kong Dollar (HKD)', value: 'HKD' },
  { label: 'Norwegian Krone (NOK)', value: 'NOK' },
  { label: 'South Korean Won (KRW)', value: 'KRW' },
]

export const tradeOptions = [
  { label: 'Construction', value: 'Construction' },
  { label: 'Electrical', value: 'Electrical' },
  { label: 'Plumbing', value: 'Plumbing' },
  { label: 'Welding', value: 'Welding' },
  { label: 'Carpentry', value: 'Carpentry' },
  { label: 'Mechanical', value: 'Mechanical' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'HVAC', value: 'HVAC' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Landscaping', value: 'Landscaping' },
  { label: 'Maintenance', value: 'Maintenance' },
  { label: 'Painting', value: 'Painting' },
  { label: 'Masonry', value: 'Masonry' },
  { label: 'Roofing', value: 'Roofing' },
  { label: 'Heavy Equipment Operation', value: 'Heavy Equipment Operation' },
]
export const reportsToOptions = [
  { label: 'CXO', value: 'cxo' },
  { label: 'President', value: 'President' },
  { label: 'Vice President (VP)', value: 'Vice President (VP)' },
  { label: 'Director', value: 'Director' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Supervisor', value: 'Supervisor' },
  { label: 'Team Lead', value: 'Team Lead' },
  { label: 'Employee', value: 'Employee' },
]
export const deptOptions = [
  { label: 'Human Resources (HR)', value: 'Human Resources (HR)' },
  { label: 'Operations', value: 'Operations' },
  { label: 'Safety and Compliance', value: 'Safety and Compliance' },
  { label: 'Quality Control/Assurance', value: 'Quality Control/Assurance' },
  { label: 'Maintenance', value: 'Maintenance' },
  { label: 'Supply Chain and Logistics', value: 'Supply Chain and Logistics' },
  { label: 'Customer Service', value: 'Customer Service' },
  { label: 'Finance and Accounting', value: 'Finance and Accounting' },
  { label: 'Training and Development', value: 'Training and Development' },
  { label: 'Marketing and Sales', value: 'Marketing and Sales' },
  {
    label: 'Information Technology (IT)',
    value: 'Information Technology (IT)',
  },
  { label: 'Administration', value: 'Administration' },
]

export const statusOption = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'All', value: undefined },
]

export const uploadDocumentOption = [
  { label: 'Pancard', value: 'pancard' },
  { label: 'Aadhar Card', value: 'aadharcard' },
  { label: 'Passport', value: 'passport ' },
  { label: 'Passport Image', value: 'passportimage' },
  { label: 'Profile Picture', value: 'profilepic' },
  { label: 'CV', value: 'cv' },
]

export const countryOption = [
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
  },
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas (the)',
    value: 'Bahamas (the)',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'Bolivia (Plurinational State of)',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'British Indian Ocean Territory (the)',
    value: 'British Indian Ocean Territory (the)',
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
  },
  {
    label: 'Cabo Verde',
    value: 'Cabo Verde',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'Cayman Islands (the)',
    value: 'Cayman Islands (the)',
  },
  {
    label: 'Central African Republic (the)',
    value: 'Central African Republic (the)',
  },
  {
    label: 'Chad',
    value: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands (the)',
    value: 'Cocos (Keeling) Islands (the)',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Comoros (the)',
    value: 'Comoros (the)',
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    value: 'Congo (the Democratic Republic of the)',
  },
  {
    label: 'Congo (the)',
    value: 'Congo (the)',
  },
  {
    label: 'Cook Islands (the)',
    value: 'Cook Islands (the)',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
  },
  {
    label: 'Czechia',
    value: 'Czechia',
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
  },
  {
    label: 'Denmark',
    value: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
  },
  {
    label: 'Dominican Republic (the)',
    value: 'Dominican Republic (the)',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Eswatini',
    value: 'Eswatini',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    label: 'Falkland Islands (the) [Malvinas]',
    value: 'Falkland Islands (the) [Malvinas]',
  },
  {
    label: 'Faroe Islands (the)',
    value: 'Faroe Islands (the)',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
  },
  {
    label: 'Finland',
    value: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    label: 'French Southern Territories (the)',
    value: 'French Southern Territories (the)',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
  },
  {
    label: 'Gambia (the)',
    value: 'Gambia (the)',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    label: 'Greece',
    value: 'Greece',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    label: 'Guam',
    value: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
  },
  {
    label: 'Holy See (the)',
    value: 'Holy See (the)',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'Iran (Islamic Republic of)',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    value: "Korea (the Democratic People's Republic of)",
  },
  {
    label: 'Korea (the Republic of)',
    value: 'Korea (the Republic of)',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: "Lao People's Democratic Republic (the)",
    value: "Lao People's Democratic Republic (the)",
  },
  {
    label: 'Latvia',
    value: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
  },
  {
    label: 'Libya',
    value: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    label: 'Macao',
    value: 'Macao',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
  },
  {
    label: 'Marshall Islands (the)',
    value: 'Marshall Islands (the)',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'Micronesia (Federated States of)',
  },
  {
    label: 'Moldova (the Republic of)',
    value: 'Moldova (the Republic of)',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'Netherlands (the)',
    value: 'Netherlands (the)',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Niger (the)',
    value: 'Niger (the)',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Niue',
    value: 'Niue',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands (the)',
    value: 'Northern Mariana Islands (the)',
  },
  {
    label: 'Norway',
    value: 'Norway',
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Palestine, State of',
    value: 'Palestine, State of',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines (the)',
    value: 'Philippines (the)',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Republic of North Macedonia',
    value: 'Republic of North Macedonia',
  },
  {
    label: 'Romania',
    value: 'Romania',
  },
  {
    label: 'Russian Federation (the)',
    value: 'Russian Federation (the)',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Réunion',
    value: 'Réunion',
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
  },
  {
    label: 'Spain',
    value: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Sudan (the)',
    value: 'Sudan (the)',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
  },
  {
    label: 'Taiwan (Province of China)',
    value: 'Taiwan (Province of China)',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    label: 'Togo',
    value: 'Togo',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands (the)',
    value: 'Turks and Caicos Islands (the)',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
  },
  {
    label: 'United Arab Emirates (the)',
    value: 'United Arab Emirates (the)',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    value: 'United Kingdom of Great Britain and Northern Ireland (the)',
  },
  {
    label: 'United States Minor Outlying Islands (the)',
    value: 'United States Minor Outlying Islands (the)',
  },
  {
    label: 'United States of America (the)',
    value: 'United States of America (the)',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'Venezuela (Bolivarian Republic of)',
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'Virgin Islands (British)',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'Virgin Islands (U.S.)',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
  },
]

export const progressOption = [
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Completed', value: 'completed' },
  { label: 'Incompleted', value: 'incompleted' },
]
export const contentType = {
  image: 'image/*',
  pdf: 'application/pdf',
  file: 'application/octet-stream',
}

export const realtionOption = [
  { label: 'father', value: 'father' },
  { label: 'mother', value: 'mother' },
  { label: 'brother', value: 'brother' },
  { label: 'sister', value: 'sister' },
]
