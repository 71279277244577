import { ReactNode, useState } from 'react'
import { Button, Text } from 'components'
import { default as ModalProvider } from 'react-modal'
import { MdOutlineEmail } from 'react-icons/md'
import { cn } from 'utils'
import { DataView } from 'pages/RequirementOverview'
import { Controller, useForm } from 'react-hook-form'
interface sendMessageProps {
  sendfunction: (vlaue: object) => void
  primaryColor: string
  children: ReactNode
  sendId: string
  candidateName: string
}

const SendMessage = ({
  children,
  primaryColor,
  sendfunction,
  sendId,
  candidateName,
}: sendMessageProps) => {
  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(!open)
  const form = useForm()
  const onsubmit = (data) => {
    sendfunction({ ...data, senderName: candidateName, senderId: sendId })
    openModal()
  }

  return (
    <>
      <button onClick={openModal}>{children}</button>
      <ModalProvider
        appElement={document.getElementById('root')}
        className="m-auto !w-[42%]"
        overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full"
        isOpen={open}
        onRequestClose={openModal}
      >
        <div className="max-h-[97vh] overflow-y-auto sm:w-full md:w-full">
          <form
            className="bg-white-A700 flex flex-col gap-[25px] items-start justify-start p-10 md:px-5 rounded-[15px] w-auto md:w-full max-w-[500px] m-auto"
            onSubmit={form.handleSubmit(onsubmit)}
          >
            <Text
              className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize"
              size="txtPoppinsMedium20Gray900"
            >
              <MdOutlineEmail className={cn(`text-${primaryColor}-500`)} /> send
              email
            </Text>
            <div className="w-full">
              <DataView
                title="Candidate Name"
                data={candidateName}
                textClass="text-base font-medium"
                dataClass="text-gray-500 text-base"
              />
              <DataView
                wrapperClass="mt-4"
                title="receiver Id"
                data={sendId}
                textClass="text-base font-medium"
                dataClass="text-gray-500 text-base"
              />

              <Controller
                name="content"
                control={form.control}
                render={({ field }) => (
                  <textarea
                    className="w-full mt-5 border-2 border-gray-200 border-solid bg-white-A700 min-h-[158px] rounded-md resize-none font-poppins"
                    placeholder="Enter Your Message"
                    name="content"
                    {...field}
                  />
                )}
              />
            </div>
            {/* //BUTTONS */}
            <div className="py-2.5 justify-center items-center gap-5 inline-flex">
              <Button
                className={cn(
                  ' border-[3px]  border-solid cursor-pointer h-[45px] min-w-[130px] py-[7px] rounded-lg text-center text-sm text-white-A700',
                  `bg-${primaryColor}-500 border-${primaryColor}-300`,
                )}
                type="submit"
              >
                Send
              </Button>
              <Button
                className="w-auto text-base text-center text-gray-700"
                onClick={openModal}
                type="button"
              >
                cancel
              </Button>
            </div>
          </form>
        </div>
      </ModalProvider>
    </>
  )
}

export default SendMessage
