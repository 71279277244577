import ModalBase from 'modals/BaseModal'
import { Input, Text } from 'components'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import useCandidate from 'hooks/useCandidate'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
const VerifyCandidate = ({ children }) => {
  const form = useForm()
  const navigate = useNavigate()
  const { verifyCandidate } = useCandidate()
  const verify = useMutation({
    mutationKey: ['verify'],
    mutationFn: verifyCandidate,
    onSuccess: (data) => {
      navigate(`/masterview/${data.id}`)
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const onSubmit = (data) => {
    const verifyObject = {
      verificationNumber: data.pan_number ?? data.passport_number,
    }
    toast.promise(verify.mutateAsync(verifyObject), {
      loading: 'Verifying Candidate...',
      success: 'candiate already present in the system.',
      error: 'Candidate not present. You can create one.',
    })
  }
  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[900px]"
      acceptFunctions={form.handleSubmit(onSubmit)}
      defalutOpen={true}
    >
      <div>
        <Text
          className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize"
          size="txtPoppinsMedium20Gray900"
        >
          Candidate Verification
        </Text>
        <Text>
          Enter PAN Card Number or Passport Number to check candidate exists or
          not.
        </Text>
      </div>
      <div>
        <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full mb-5">
          <Text
            className="text-[15px] text-gray-900 w-auto"
            size="txtPoppinsMedium15"
          >
            PAN Card Number
          </Text>
          <Input
            placeholder="PAN Card Number"
            className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
            wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-2.5 pr-[35px] py-3.5 rounded-[9px] w-full"
            name="pan_number"
            control={form.control}
          />
        </div>
        <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full">
          <Text
            className="text-[15px] text-gray-900 w-auto"
            size="txtPoppinsMedium15"
          >
            Passport Number
          </Text>
          <Input
            placeholder="Passport Number"
            className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
            wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-2.5 pr-[35px] py-3.5 rounded-[9px] w-full"
            name="passport_number"
            control={form.control}
          />
        </div>
      </div>
    </ModalBase>
  )
}

export default VerifyCandidate
