import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Img, Text } from 'components'
import toast from 'react-hot-toast'
import useAccount from 'hooks/useAccount'
import { useMutation } from 'react-query'
import useAuth from 'hooks/useAuth'
import Cookies from 'universal-cookie'
const ForgotOtp = () => {
  const navigate = useNavigate()
  const { verifyOtp } = useAccount()
  const [opt, setOtp] = useState({})
  const cookies = new Cookies()
  const optHandler = useMutation({
    mutationFn: verifyOtp,
    mutationKey: ['verifyOtp'],
    onError: (error: any) => {
      toast.error(error?.response?.data?.message, {
        id: 'verifyOtp',
      })
    },
    onSuccess: (data) => {
      toast.success('opt verified successfully', {
        id: 'verifyOtp',
      })
      navigate('/reset-password')
    },
    onMutate: (data) => {
      toast.loading('verfiying the otp...', {
        id: 'verifyOtp',
      })
    },
  })

  const optVerify = async () => {
    const optObjectToNumber = Object.keys(opt).map((key) => {
      return Number(opt[key])
    })
    optHandler.mutate(optObjectToNumber.join(''))

    cookies.set('c', optObjectToNumber.join(''))
  }

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, maxLength } = event.target
    const nextInput = event.target.nextElementSibling as HTMLInputElement
    const prevInput = event.target.previousElementSibling as HTMLInputElement
    setOtp({ ...opt, [event.target.name]: value })
    if (value.length >= maxLength && nextInput) {
      nextInput.focus()
    } else if (value.length === 0 && prevInput) {
      prevInput.focus()
    }
  }

  const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const prevInput = event.currentTarget
      .previousElementSibling as HTMLInputElement
    const currentValue = event.currentTarget.value

    if (event.key === 'Backspace' && currentValue === '' && prevInput) {
      prevInput.focus()
    }
  }

  return (
    <div className="bg-blue-A200 min-h-[100vh] flex sm:flex-col md:flex-col flex-row sm:gap-10 md:gap-10 items-center justify-between md:justify-center md:px-10 px-20 sm:px-5 w-auto sm:w-full md:w-full">
      {' '}
      <Img
        className="h-full w-[418px] md:hidden"
        src="images/img_image.svg"
        alt="image"
      />
      <div className="bg-white-A700 flex flex-col gap-[15px] items-center justify-center pb-20 pt-10 md:px-5 px-[30px] rounded-[75px] shadow-bs2 w-[547px] sm:w-full">
        <div className="flex flex-col font-montserrat gap-[15px] items-center justify-center w-full">
          <div className="h-[89px] md:h-[50px] w-20 sm:w-full flex items-center justify-center">
            <Img
              className="object-contain w-full h-full"
              src="images/img_logo.png"
              alt="logo"
            />
          </div>

          <Text
            className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto"
            size="txtMontserratRomanSemiBold32"
          >
            Ambe Consultancy Pvt. Ltd.
          </Text>
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-2 pt-5 font-poppins">
          <Text className="w-auto text-xl font-medium text-black-900">
            Password Reset
          </Text>
          <Text className="w-auto text-sm font-medium text-gray-900_a8">
            we sent a code to your registered email
          </Text>
        </div>

        <div className="w-full h-[82px] px-10 justify-between items-center inline-flex">
          {[1, 2, 3, 4].map((item) => (
            <input
              name={item.toString()}
              key={item}
              maxLength={1}
              onChange={handleOtpChange}
              onKeyDown={handleBackspace}
              type="number"
              className="flex justify-center items-center text-[61px] font-poppins font-[400] px-[12px] py-[15px] border border-solid h-[82px] rounded-[4px] w-[69px] border-[#809FA9] "
            />
          ))}
        </div>
        {/* <div className="h-[17px] justify-center items-center gap-3 inline-flex">
          <div className="text-zinc-600 text-sm font-medium font-['Poppins'] leading-[14px]">
            Didn’t receive OTP code?
          </div>
          <div className="text-blue-500 text-sm font-medium font-['Poppins'] underline leading-[16.80px]">
            Resend Code
          </div>
        </div> */}
        <Button
          className="common-pointer bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer font-poppins font-[500] py-[18px] rounded-[10px] text-center text-white-A700 text-[16px] w-full"
          onClick={() => {
            optVerify()
          }}
        >
          Verify
        </Button>
        <div className="flex justify-start w-full">
          <Button
            type="button"
            className="cursor-pointer text-neutral-900 text-base font-medium font-['Poppins'] leading-loose"
            onClick={() => navigate('/')}
          >
            Back to login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ForgotOtp
