import useclient from './useClient'
import { apis, baseURL } from '../service'
const useReception = () => {
  const { getAuthHeader } = useclient()
  const getReceptionDashboard = async () => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/dashboard`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addVisitor = async (visitor: object) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/addVisitorsLog`,
        {
          data: visitor,
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('error in the while creating the visitor log', error)
      throw error
    }
  }

  const addCaller = async (caller: object) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/addCallLog`,
        {
          data: caller,
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('error in the while creating the caller log', error)
      throw error
    }
  }

  const getVisitorLog = async (params) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/visitorlog/list`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )

      return data.data
    } catch (error) {
      console.error('error in the while getting the visitor log', error)
      throw error
    }
  }

  const getCallerLog = async (params) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/reception/api/v1/calllog/list`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )

      return data.data
    } catch (error) {
      console.error('error in the while getting the caller log', error)
      throw error
    }
  }

  return {
    getReceptionDashboard,
    addVisitor,
    addCaller,
    getVisitorLog,
    getCallerLog,
  }
}

export default useReception
