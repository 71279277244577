import { Rings } from 'react-loader-spinner'
import styled from 'styled-components'

const Spinner = styled((props) => (
  <div {...props}>
    <Rings height="80" width="80" color="#F19E38" ariaLabel="loading" />
  </div>
))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 95, 227, 0.3);
  z-index: 100000;
`

export default Spinner
